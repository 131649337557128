<template>
  <div>
    <div class="box">
      <div class="text">
        <br>
        图片要求<br>
        -名称：需与菜品名称完全一致<br>
        -格式：支持jpg、png格式<br>
        -大小：单张不大于2M<br>
        -尺寸：推荐640x640像素<br><br>
      </div>
      <div class="icons-btn" @click="onSelectFile">
        <i class="el-icon-plus"></i>
        <div>点击上传</div>
      </div>
    </div>
    <div id="filebody"></div>
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :before-close="dialogBeforeClose" title="批量上传" :visible.sync="dialogVisible" width="767px" append-to-body>
      <div v-if="dialogVisible">
        <div class="tishi"> <i class="el-icon-warning"></i> 注意：上传过程中，请勿刷新、退出！</div>
        <div class="jindu-box">
          <div class="sum-box">
            <div>上传总数:{{upFileList.length}}</div>
            <div class="success">成功:{{successSum}}/{{upFileList.length}}</div>
            <div><el-button type="danger" @click="tableToExcel" size="mini">失败:{{errFileList.length}}/{{upFileList.length}} <i class="el-icon-download"></i></el-button></div>
          </div>
          <div class="up-btn">
            <el-button type="primary" size="mini" v-if="upfileIndex!=upFileList.length-1" @click="toggerUp">{{isRun?"暂停":"恢复"}}上传</el-button>
          </div>
        </div>
        <br>
        <el-progress :text-inside="true" :stroke-width="16" :percentage="jinduNum"></el-progress>
        <br>
        <div class="upimg-list">
          <upFile ref="fileObj" @onError="onUpfileError" @onSuccess="onUpfileSuccess" v-for="item in upFileList" :upfile="item" :key="item.name" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { resHandleFn } from "@/http.js";
import { baseurl } from "@/base.js";
import upFile from "./upFile.vue";
export default {
  components: { upFile },
  data() {
    return {
      dialogVisible: false,
      upFileList: [],
      isRun: true,
      upfileIndex: -1,
      errFileList: [],
      successSum: 0,
      dialogBeforeClose: (done) => {
        if (this.isFinish) return done && done();
        this.$confirm("关闭将会终止图片上传, 是否关闭?", "提示", {
          confirmButtonText: "确认关闭",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.isRun = false;
          done();
        });
      },
    };
  },
  computed: {
    jinduNum() {
      return Math.round(
        ((this.successSum + this.errFileList.length) / this.upFileList.length) *
          100
      );
    },
    isFinish() {
      return (
        this.successSum + this.errFileList.length == this.upFileList.length
      );
    },
  },
  methods: {
    tableToExcel() {
      if (this.errFileList.length == 0) return;
      let str = "名称,错误原因\n";
      this.errFileList.forEach((v) => {
        str += `${v.name},${v.text}\n`;
      });
      const uri =
        "data:text/csv;charset=utf-8,\ufeff" + encodeURIComponent(str);
      const link = document.createElement("a");
      link.href = uri;
      link.download = "错误文件.csv";
      link.click();
    },
    toggerUp() {
      this.isRun = !this.isRun;
      if (this.isRun) {
        this.onUpfileNext();
      } else {
        this.$message("待上传文件已暂停上传!");
      }
    },
    onUpfileSuccess() {
      this.successSum++;
      this.onUpfileNext();
    },
    onUpfileError(data) {
      this.errFileList.push(data);
      this.onUpfileNext();
    },
    onUpfileNext() {
      if (this.isFinish) {
        this.$message({
          message: "上传完成！",
          type: "success",
        });
        this.$emit("load");
        return;
      }
      if (this.upfileIndex == this.upFileList.length - 1) {
        this.isRun = false;
        return;
      }
      if (!this.isRun) return;
      let nextFile = this.$refs.fileObj[this.upfileIndex + 1];
      if (nextFile) {
        this.upfileIndex++;
        nextFile.onUpFileFn();
      } else {
        this.isRun = false;
      }
    },
    onSelectFile() {
      this.upFileList = null;
      document.getElementById("filebody").innerHTML =
        '<input accept="image/png,image/jpeg" multiple id="fileInput" type="file">';
      document.getElementById("fileInput").onchange = (event) => {
        this.errFileList = [];
        this.upFileList = event.target.files;
        console.log(this.errFileList, event.target.files);
        this.dialogVisible = true;
        this.isRun = true;
        this.upfileIndex = -1;
        this.successSum = 0;
        this.$nextTick(() => {
          this.onUpfileNext();
        });
      };
      document.getElementById("fileInput").click();
    },
  },
};
</script>

<style scoped>
#table-excel,
#filebody {
  display: none;
}
.upimg-list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 500px;
}
.up-btn {
  display: flex;
  align-items: center;
}
.run {
  animation: myShadow 0.4s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}
@keyframes myShadow {
  from {
    box-shadow: 0 0 4px 0 #409eff;
  }
  to {
    box-shadow: 0 0 12px 0 #409eff;
  }
}

.jindu {
  background-color: #409eff;
  border-radius: 5px;
  height: 10px;
  width: 0;
}
.jindu-box {
  height: 50px;
  background: #f8f6f6;
  border-radius: 5px;
  display: flex;
  align-content: center;
  position: relative;
  overflow: hidden;
}
.sum-box {
  display: flex;
  align-items: center;
  padding: 0 10px;
}
.sum-box > div {
  width: 150px;
  z-index: 2;
}
.sum-box .success {
  color: #67c23a;
}
.sum-box .error {
  color: #f56c6c;
  cursor: pointer;
  transition: all 0.1s;
}
.sum-box .error:hover {
  color: crimson;
}
.tishi {
  background-color: #e6a23c;
  color: #fff;
  display: inline-block;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 10px;
}
.box {
  display: flex;
}
.icons-btn {
  font-size: 25px;
  color: #8c939d;
  width: 138px;
  height: 138px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f6fc;
}
.icons-btn:hover {
  background-color: #dedede;
}
.icons-btn div {
  font-size: 20px;
  margin-top: 10px;
}
.text {
  font-size: 14px;
  color: #606266;
  padding: 0 40px;
  line-height: 1.5;
}
.right {
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
}
</style>