<template>
  <div class="details-container">
    <div class="details-left">
      <el-scrollbar style="height: calc(100% - 60px);">
        <div style="padding-right:15px">
          <div class="details-box">
            <div class="details-title">订单金额</div>
            <div class="details-content">
              <div class="content-price">
                <img src="@/assets/image/img_dingdanjine.png" alt="">
                <div>
                  <div class="price-money">订单金额(元)</div>
                  <div class="price-num">{{ruleForm.shouldPaymentAmount| money}}</div>
                </div>
              </div>
              <div class="content-price">
                <img src="@/assets/image/img_yingshoujine.png" alt="">
                <div>
                  <div class="price-money">应收金额(元))</div>
                  <div class="price-num">{{ ruleForm.shouldReceiveAmount | money }}</div>
                </div>
              </div>
              <div class="content-price">
                <img src="@/assets/image/img_youhuijine.png" alt="">
                <div>
                  <div class="price-money">优惠金额(元)</div>
                  <div class="price-num">{{ (ruleForm.discountsAmount) | money }}</div>
                </div>
              </div>
              <div class="content-price">
                <img src="@/assets/image/img_shishoujine.png" alt="">
                <div>
                  <div class="price-money">实收金额(元)</div>
                  <div class="price-num">{{ ruleForm.reallyReceiveAmount | money }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="details-box">
            <div class="details-title">交易信息</div>
            <div class="details-content">
              <div class="content-deal">
                <div class="price-money">桌位费(元)</div>
                <div class="price-num">{{ ruleForm.restaurantSeatFeeAmount | money }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">打包费(元)</div>
                <div class="price-num">{{ruleForm.packingFeeAmount| money}}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">餐具费(元)</div>
                <div class="price-num">{{ruleForm.tablewareFeeAmount| money}}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">菜品原价(元)</div>
                <div class="price-num">{{ruleForm.orderOriginAmount| money}}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">菜品会员价(元)</div>
                <div class="price-num">{{ruleForm.orderMemberAmount| money}}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">抹零金额(元)</div>
                <div class="price-num">{{ruleForm.notCountAmount | money}}</div>
              </div>
            </div>
          </div>
          <div class="details-box">
            <div class="details-title">订单信息</div>
            <div class="details-content">
              <div class="content-deal">
                <div class="price-money">订单编号</div>
                <div class="price-txt">{{ ruleForm.orderNo }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">订单状态</div>
                <div class="price-txt">{{ ruleForm.orderStatusStr }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">下单时间</div>
                <div class="price-txt">{{ ruleForm.orderTimeStr }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">支付方式</div>
                <div class="price-txt">{{ ruleForm.paymentTypeStr||"—" }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">支付状态</div>
                <div class="price-txt" style="color: #FF6969;">{{ ruleForm.paymentStatusStr }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">支付时间</div>
                <div class="price-txt">{{ ruleForm.paymentTimeStr||"—" }}</div>
              </div>

              <template v-if="ruleForm.paymentType==10">
                <div class="content-deal">
                  <div class="price-money">挂账人</div>
                  <div class="price-txt">{{ ruleForm.pendingOrderUserName}}</div>
                </div>
                <div class="content-deal">
                  <div class="price-money">挂账手机号</div>
                  <div class="price-txt">{{ ruleForm.pendingOrderPhone }}</div>
                </div>
                <div class="content-deal">
                  <div class="price-money">挂账备注</div>
                  <div class="price-txt">{{ ruleForm.pendingOrderRemark||"—" }}</div>
                </div>
              </template>

              <div class="content-deal">
                <div class="price-money">会员姓名</div>
                <div class="price-txt">{{ ruleForm.memberName ||"—"}}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">会员手机号</div>
                <div class="price-txt">{{ ruleForm.mobile||"—" }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">优惠信息</div>
                <div class="price-txt">{{ ruleForm.activityName||"—" }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">交易单号</div>
                <div class="price-txt">{{ ruleForm.transactionId||"—" }}</div>
              </div>
              <div class="content-deal">
                <div class="price-money">收银员</div>
                <div class="price-txt">{{ ruleForm.cashierNo ||"—"}}</div>
              </div>

            </div>
          </div>
        </div>

      </el-scrollbar>
      <div class="footer_zin">
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <span class="dayinReceipt" v-if="ruleForm.orderStatus == '2'">打印至厨房</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleCommand('a')">打印整单</el-dropdown-item>
            <!-- //<el-dropdown-item command="b" v-if="flag3">打印加菜单</el-dropdown-item>
            //<el-dropdown-item command="c">打印退菜单</el-dropdown-item>  -->
          </el-dropdown-menu>
        </el-dropdown>
        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <span class="dayinReceipt" v-if="ruleForm.orderMode != 2&&ruleForm.orderStatus != '2' && paymentMode != '1'">打印至厨房</span>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleCommand('a')">打印整单</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <span class="dayinReceipt" @click="print('4')" v-if="ruleForm.orderMode != 2&&ruleForm.orderStatus !=2">打印预结单</span>
        <span class="dayinReceipt" @click="print('3')" v-if="ruleForm.orderStatus == '2'">打印结账单</span>
        <!-- <span class="dayinReceipt ok" v-if="But.Payment.power() && ruleForm.orderStatus == '1'" @click="orderpay">确认结账</span> -->
      </div>
    </div>
    <div class="details-right">
      <div class="details-box right-flex">
        <div class="table-info">
          <div class="table-header">
            <div v-if="ruleForm.orderMode != 2">
              <div class="table-title">{{ruleForm.tableRegionName}}-{{ ruleForm.tableName }}</div>
              <div class="table-orderman">
                <span>下单员:</span>
                {{ ruleForm.orderUserNo }}
              </div>
            </div>
            <div v-else>
              <span>取餐码：{{ ruleForm.tableName | splitD }}</span>
            </div>
          </div>
          <div v-if="ruleForm.orderMode != 2" class="table-seta">
            {{ ruleForm.tableNo }}座
          </div>
        </div>
        <div class="table-jin">
          <el-scrollbar style="height:calc(100% - 50px)">
            <div class="table-desher">
              <el-table ref="multipleTable" :data="orderDishers" tooltip-effect="dark" style="width: 100%" size="small">
                <el-table-column prop="dishersName" label="菜品名称" align="left">
                  <template slot-scope="scope">
                    <p>
                      <span class="cFF3B3B" v-if="scope.row.priceType==5">(称)</span>
                      <span class="cFF3B3B" v-if="scope.row.priceType==2">(时)</span>
                      <span class="cFF3B3B" v-if="scope.row.priceType==3">(赠)</span>
                      <span class="cFF3B3B" v-if="scope.row.priceType==4">(特)</span>
                      {{ scope.row.dishersName }}
                      <el-popover trigger="hover" placement="bottom" v-if="scope.row.dishersRemark">
                        <p>{{ scope.row.dishersRemark }}</p>
                        <span slot="reference" class="name-wrapper">
                          <img src="@/assets/image/icon_beizhu_1.png" alt="" srcset="">
                        </span>
                      </el-popover>
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="selectCount" label="数量/重量" align="left">
                  <template slot-scope="scope">
                    {{ scope.row.dishesPriceType==1?scope.row.disherUnitValue:scope.row.selectCount}}
                  </template>
                </el-table-column>
                <el-table-column prop="priceUnitName" label="单位" align="left"></el-table-column>
                <el-table-column prop="price" label="单价" align="left">
                  <template slot-scope="scope">
                    <p>¥{{ scope.row.price | money }}</p>
                    <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberPrice != '0'">
                      ¥{{ scope.row.memberPrice | money }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column prop="totalPrice" label="小计" align="left">
                  <template slot-scope="scope">
                    <p>¥{{ scope.row.totalAmount | money }}</p>
                    <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberTotalAmount != '0'">
                      ¥{{ scope.row.memberTotalAmount | money }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column label="操作人" align="left">
                  <template slot-scope="scope">
                    {{scope.row.operatorName||scope.row.operatorNo}}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
          <div class="table-remark">备注:{{ ruleForm.orderRemark }}</div>
        </div>
        <div class="table-tui">
          <div class="table-title tui-title">
            退菜信息
          </div>
          <template v-if="tableData.length!=0">
            <el-scrollbar style="height:calc(100% - 50px)">
              <div class="table-tui-list">
                <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini">
                  <el-table-column prop="dishersName" label="菜品名称" align="left">
                    <template slot-scope="scope">
                      <p>
                        {{ scope.row.dishersName }}
                        <el-popover trigger="hover" placement="bottom" v-if="scope.row.dishersRemark">
                          <p>{{ scope.row.dishersRemark }}</p>
                          <span slot="reference" class="name-wrapper">
                            <img src="@/assets/image/icon_beizhu_1.png" alt="" srcset="">
                          </span>
                        </el-popover>
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="selectCount" label="数量/重量" align="left">
                    <template slot-scope="scope">
                      {{ scope.row.dishesPriceType==1?scope.row.disherUnitValue:scope.row.selectCount}}
                  </template>
                  </el-table-column>
                  <el-table-column prop="priceUnitName" label="单位" align="left"></el-table-column>
                  <el-table-column prop="price" label="单价" align="left">
                    <template slot-scope="scope">
                      <p>¥{{ scope.row.price | money }}</p>
                      <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberPrice != '0'">
                        ¥{{ scope.row.memberPrice | money }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column prop="totalAmount" label="小计" align="left">
                    <template slot-scope="scope">
                      <p>¥{{ scope.row.totalAmount | money }}</p>
                      <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.totalMemPrice != '0'">
                        ¥{{ scope.row.memberTotalAmount | money }}
                      </p>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作人" align="left">
                    <template slot-scope="scope">
                      {{scope.row.operatorName||scope.row.operatorNo}}
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-scrollbar>
          </template>
          <div v-else class="empty-list tui-wu">
            <img src="@/assets/image/img_wucaipin.png" alt="">
            <div>暂无退菜信息</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="contant_left">
      <div class="footer_zin">
        <div>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <span class="dayinReceipt" v-if="ruleForm.orderStatus == '2' && paymentMode == '1'">打印至厨房</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleCommand('a')">打印整单</el-dropdown-item>
              <el-dropdown-item command="b" v-if="flag3">打印加菜单</el-dropdown-item> 
               <el-dropdown-item command="c">打印退菜单</el-dropdown-item> 
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <span class="dayinReceipt" v-if="ruleForm.orderStatus == '1' && paymentMode != '1'">打印至厨房</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="handleCommand('a')">打印整单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span class="dayinReceipt" @click="print('4')" v-if="ruleForm.orderMode != 2&&(ruleForm.orderStatus == '1' || ruleForm.orderStatus == '3')">打印预结单</span>
          <span class="dayinReceipt" @click="print('3')" v-if="ruleForm.orderStatus == '2'">打印结账单</span>
        </div>
        <div class="footer_btn">
          <el-button type="primary" size="mini" @click="closeorder">返回餐台</el-button>
          <el-button v-if="But.Payment.power() && ruleForm.orderStatus == '1'" type="primary" size="mini" @click="orderpay">买单</el-button>
        </div>
      </div>
    </div>
    <div class="phone_inner">
      <div class="header_phone">
        <div v-if="ruleForm.orderMode != 2">
          <span>{{ruleForm.tableRegionName}}-{{ ruleForm.tableName }}</span>
          <span>{{ ruleForm.tableNo }}座</span>
        </div>
        <div v-else>
          <span>取餐码：{{ ruleForm.tableName | splitD }}</span>
        </div>
        <div>
          <span>
            <span>下单员:</span>
            {{ ruleForm.orderUserNo }}
          </span>
          <span>{{ ruleForm.orderTime }}</span>
        </div>
      </div>
      <div class="detailtab">
        <el-table ref="multipleTable" :data="orderDishers" tooltip-effect="dark" style="width: 100%" size="mini" max-height="360" default-expand-all row-key="disherId" highlight-current-row
          :tree-props="{
            children: 'childOrderDisher',
            hasChildren: 'hasChildren',
          }">
          <el-table-column prop="dishersName" label="菜品名称">
            <template slot-scope="scope">
              <p>
                <span class="cFF3B3B" v-if="scope.row.priceType==2">(时)</span>
                <span class="cFF3B3B" v-if="scope.row.priceType==3">(赠)</span>
                <span class="cFF3B3B" v-if="scope.row.priceType==4">(特)</span>
                {{ scope.row.dishersName }}
              </p>
              <p>
                <span class="c999999">{{[scope.row.priceUnitName,scope.row.tasteName]|filterNullToString}}</span>&nbsp;
                <el-popover trigger="hover" placement="left" v-if="scope.row.dishersRemark">
                  <p>{{ scope.row.dishersRemark }}</p>
                  <span slot="reference" class="name-wrapper">
                    <el-tag size="medium">备注详情</el-tag>
                  </span>
                </el-popover>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="selectCount" label="数量"></el-table-column>
          <el-table-column prop="priceUnitName" label="单位"></el-table-column>
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">
              <p>¥{{ scope.row.price | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberPrice != '0'">
                ¥{{ scope.row.memberPrice | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" label="小计">
            <template slot-scope="scope">
              <p>¥{{ scope.row.totalAmount | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberTotalAmount != '0'">
                ¥{{ scope.row.memberTotalAmount | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="operatorNo" label="操作人"></el-table-column>
        </el-table>
        <div class="rack">备注:{{ ruleForm.orderRemark }}</div>
      </div>
      <div class="detailtab" v-if="ruleForm.orderMode != 2">
        <div class="title_inner">退菜信息</div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini" highlight-current-row max-height="360" default-expand-all row-key="disherId" :tree-props="{
            children: 'childOrderDisher',
            hasChildren: 'hasChildren',
          }">
          <el-table-column prop="dishersName" label="菜品名称">
            <template slot-scope="scope">
              <p>{{ scope.row.dishersName }}</p>
              <p class="huiyuan">
                <el-popover trigger="hover" placement="top" v-if="scope.row.remark">
                  <p>{{ scope.row.remark }}</p>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">备注详情</el-tag>
                  </div>
                </el-popover>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="selectCount" label="数量"></el-table-column>
          <el-table-column prop="priceUnitName" label="单位"></el-table-column>
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">
              <p>¥{{ scope.row.price | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberPrice != '0'">
                ¥{{ scope.row.memberPrice | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="totalAmount" label="小计">
            <template slot-scope="scope">
              <p>¥{{ scope.row.totalAmount | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.totalMemPrice != '0'">
                ¥{{ scope.row.memberTotalAmount | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="operatorNo" label="操作人"></el-table-column>
        </el-table>
        <div class="rack">备注:{{ ruleForm.backOrderRemark }}</div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  created() {
    this.detail();
    this.merload();
  },
  props: ["query"],
  filters: {
    filterNullToString(val) {
      return val.filter((v) => !!v).join("+");
    },
    splitD(v) {
      return v.replace(/[^\d]/g, "");
    },
  },
  data() {
    return {
      But: window.UserPower.serve.orderdetail,
      flag3: false,
      paymentMode: "",
      statusobj: {
        1: "正常 ",
        2: "退菜",
      },
      memberTypeobj: {
        1: "普通会员",
      },
      tableData: [],
      orderDishers: [],
      ruleForm: {},
      paymentTypeobj: {
        1: "现金",
        2: "扫码",
        3: "刷卡",
        4: "其他",
        5: "免单",
        6: "账户支付",
      },
      rules: {},
      printKinds: [],
      printTypes: [],
      printKindsi: "",
      openCommonMermber: "",
    };
  },
  methods: {
    merload() {
      this.$http.get(`/order-admin/w/base/merchantInfo`).then((res) => {
        this.paymentMode = res.merchantInfoVo.paymentMode;
        this.openCommonMermber = res.merchantInfoVo.openCommonMermber;
        if (this.paymentMode != "1") {
          this.flag3 = true;
        }
      });
    },
    handleCommand(command) {
      this.printOrder({
        printTypes: ["2", "3"],
        printKinds: ["0"],
        orderPrintType: 2,
      });
    },
    print(val) {
      let data = {
        printTypes: ["1", "3"],
        printKinds: ["4"],
        orderPrintType: 1,
      };
      this.printKindsi = val;
      if (val == 3) {
        data.printKinds = ["3"];
      }
      this.printOrder(data);
    },
    async printOrder({ printTypes, printKinds, orderPrintType }) {
      // printTypes-打印机类型:1前台，2后台，3公用。
      // printKinds-打印菜单类型:0-点菜单，1-加菜单，2-退菜单，3-结账单，4-预结单
      // orderPrintType?-打印指定类型:1-前台打印机-点餐单，预结单,  2-后厨打印机-点餐单-厨房单
      let data = {
        orderNo: this.query.orderId,
        printTypes,
        printKinds,
        orderPrintType,
      };
      await this.$http.post("/cashier-api/api/print/getPrintMsg", data);
      this.$message({
        message: "打印成功",
        type: "success",
      });
    },
    printchencken() {
      let data = {
        orderId: this.query.orderId,
        printTypes: this.printTypes,
        printKinds: this.printKinds,
        printerType: 2,
      };
      if (this.printKindsi == "0") {
        data.printKinds = this.printKinds;
        data.printstatus = "1";
      } else if (this.printKindsi == "3") {
        delete data.printTypes;
      }
      if (this.paymentMode == "1") {
        this.printTypes = [];
      }
      if (this.ruleForm.orderMode == 2) {
        data.orderMode = this.ruleForm.orderMode;
      }
      this.$http
        .post(`/order-admin/mqtt/print`, data)
        .then((res) => {
          this.$message({
            message: "打印成功",
            offset: 160,
            type: "success",
          });
          this.printKinds = [];
          this.printTypes = [];
        })
        .catch((_) => {
          this.printKinds = [];
          this.printTypes = [];
        });
    },
    closeorder() {
      this.$router.push({
        path: "/selectTable",
        meta: {
          title: "点餐",
        },
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.detail();
    },
    async detail() {
      let { data } = await this.$http.post(
        "/cashier-api/api/orderNew/getUserHistoryOrder",
        {
          orderNo: this.query.orderId,
        }
      );
      this.ruleForm = data.orderUnsaveCacheVo;
      this.tableData = data.reduceOrderDishes;
      this.orderDishers = data.addOrderDishes;
    },
    orderpay() {
      // this.$router.push({
      //   path: "/serve",
      //   query: {
      //     orderNo: this.ruleForm.orderNo,
      //     ordertype: 1
      //   }
      // });
      // localStorage.setItem("payflag", false);
    },
  },
};
</script>

<style src="../orderdetail/details.css" scoped>
</style>
