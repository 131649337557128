<template>
  <div class="view-contion" v-loading="loading">
    <div class="pie-view">
      <div id="main"></div>
      <div class="descire">总计：{{ totalAmount }}元</div>
    </div>
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
      >
        <el-form-item label="流水日期">
          <el-date-picker
            v-model="formInline.value1"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :picker-options="notTodayPass"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
          >查询</el-button
        >
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
      :row-class-name="setCLassName"
    >
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod"
        width="50"
      ></el-table-column>
      <el-table-column prop="statisticalDate" label="日期"></el-table-column>
      <el-table-column prop="aliPayment" label="支付宝">
        <template slot-scope="scope">{{
          scope.row.aliPayment | money
        }}</template>
      </el-table-column>
      <el-table-column prop="wechatPayment" label="微信">
        <template slot-scope="scope">{{
          scope.row.wechatPayment | money
        }}</template>
      </el-table-column>
      <el-table-column prop="cashPayment" label="现金">
        <template slot-scope="scope">{{
          scope.row.cashPayment | money
        }}</template>
      </el-table-column>
      <el-table-column prop="cardPayment" label="银联">
        <template slot-scope="scope">{{
          scope.row.cardPayment | money
        }}</template>
      </el-table-column>
      <el-table-column prop="onAccountPayment" label="挂账">
        <template slot-scope="scope">{{
          scope.row.onAccountPayment | money
        }}</template>
      </el-table-column>
      <el-table-column prop="accountPayment" label="账户余额">
        <template slot-scope="scope">{{
          scope.row.accountPayment | money
        }}</template>
      </el-table-column>
      <el-table-column
        prop="shuxinEPay"
        label="蜀信e支付"
        v-if="obj.platCode == '900014'"
      >
        <template slot-scope="scope">{{
          scope.row.shuxinEPay | money
        }}</template>
      </el-table-column>
      <el-table-column prop="ortherPayment" label="其他">
        <template slot-scope="scope">{{
          scope.row.ortherPayment | money
        }}</template>
      </el-table-column>
      <el-table-column prop="totalAmount" label="总金额">
        <template slot-scope="scope">{{
          scope.row.totalAmount | money
        }}</template>
      </el-table-column>
      <el-table-column prop="freeOrder" label="免单">
        <template slot-scope="scope">{{
          scope.row.freeOrder | money
        }}</template>
      </el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import echart from "./charts";
export default {
  created() {
    this.obj = JSON.parse(localStorage.getItem("merchantInfoVo"));
  },
  mounted() {
    var chartDom = document.getElementById("main");
    this.setChart = echart(chartDom);
    this.search();
  },
  data() {
    return {
      setChart: null,
      totalAmount: 0,
      But: window.UserPower.Salesstatistics.transactionAdminQuit,
      obj: {},
      formInline: {
        cashDateStart: "",
        cashDateEnd: "",
        value1: null,
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    };
  },
  methods: {
    setCLassName({ row }) {
      if (row.zong) {
        return "bg-eee";
      }
    },
    loadPieData() {
      return this.$http
        .post("/order-admin/w/statistic/cash/chart", {
          ...this.formInline,
        })
        .then((res) => {
          let data = res.data;
          let arr = [
            { value: data.wechatPayment, name: "微信" },
            { value: data.aliPayment, name: "支付宝" },
            { value: data.cardPayment, name: "银联" },
            { value: data.cashPayment, name: "现金" },
            { value: data.shuxinEPay, name: "蜀信e支付" },
            { value: data.onAccountPayment, name: "挂帐" },
            // { value: data.freeOrder, name: "免单" },
            { value: data.accountPayment, name: "账户余额" },
            { value: data.ortherPayment, name: "其它" },
          ];
          if (localStorage.getItem("changyum") != "sc") {
            arr.splice(4, 1);
          }
          this.setChart(arr);
         
          this.totalAmount = +data.totalAmount || 0;
        });
    },
    reset() {
      this.formInline.value1 = [];
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    indexMethod(index) {
      if (index == this.tableData.length - 1) {
        return "总计";
      }
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.cashDateStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.cashDateEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.loadPieData();
      this.$http
        .post("/order-admin/w/statistic/cash/s/page", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          res.data.pageInfo.records.push({
            onAccountPayment: res.data.onAccountPayment,
            totalAmount: res.data.totalAmount,
            aliPayment: res.data.aliPayment,
            wechatPayment: res.data.wechatPayment,
            cashPayment: res.data.cashPayment,
            cardPayment: res.data.cardPayment,
            freeOrder: res.data.freeOrder,
            shuxinEPay: res.data.shuxinEPay,
            accountPayment: res.data.accountPayment,
            ortherPayment: res.data.ortherPayment,
            statisticalDate: " ",
            zong: true,
          });
          this.tableData = res.data.pageInfo.records;
          this.page.total = res.data.pageInfo.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.pie-view {
  height: 340px;
  width: 620px;
  box-shadow: 0px -1px 3px 3px #22202017;
  border-radius: 10px;
  margin-bottom: 10px;
}
#main {
  width: 100%;
  height: 300px;
}
.descire {
  font-size: 18px;
  color: #333333;
  text-align: center;
}
</style>


