let baseurl;
let codeurl;
let LocationKey;
let mqttBase;
/***********测试地址***************/
// baseurl = 'http://run.buybal.com';
// codeurl = "http://run.buybal.com/diancandown"
// LocationKey = {
//     "diancan.buybal.com": ["normal", 1],
//     "zjk.diancan.buybal.com": ["zjk", 2],
//     "scrcu.diancan.buybal.com": ["sc", 3]
// }
// mqttBase = {
//     ip: "emq.buybal.com",
//     port: 8084,
//     useSSL: true,
//     userName: "cline",
//     passWord: "cline"
// };
/************end******************/

/***********预生产地址***************/
// baseurl = "https://prediancan.chinanums.com";
// codeurl = "https://prediancan.chinanums.com/diancandown";
// LocationKey = {
//     "prediancan.chinanums.com": ["normal", 1],
//     "prezjk.diancan.chinanums.com": ["zjk", 2],
//     "prescrcu.diancan.chinanums.com": ["sc", 3]
// }
// mqttBase = {
//     ip: "emq.chinanums.com",
//     port: 8184,
//     useSSL: true,
//     userName: "clinet",
//     passWord: "clinet"
// }
/************end******************/

/***********生产地址***************/
baseurl = "https://diancan.chinanums.com";
codeurl = "https://diancan.chinanums.com/diancandown";
LocationKey = {
    "diancan.chinanums.com": ["normal", 1],
    "zjk.diancan.chinanums.com": ["zjk", 2],
    "scrcu.diancan.chinanums.com": ["sc", 3]
}
mqttBase = {
    ip: "emq.chinanums.com",
    port: 8184,
    useSSL: true,
    userName: "clinet",
    passWord: "clinet"
}
/************end******************/

export {
    baseurl,
    codeurl,
    LocationKey,
    mqttBase
};
