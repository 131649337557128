<template>
  <div class="view">
    <div class="view-contion" v-loading="loading">
      <div class="view-search">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="菜品分类" prop="categoryId">
            <el-select
              v-model="formInline.categoryId"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in equipmentlist"
                :key="item.categoryId"
                :label="item.categoryName"
                :value="item.categoryId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="分类状态" prop="categoryStatus">
            <el-select
              v-model="formInline.categoryStatus"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="(v, k) in equipmentstatus"
                :key="k"
                :label="v"
                :value="k"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="添加时间">
            <el-date-picker
              v-model="formInline.value1"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="notTodayPass"
            ></el-date-picker>
          </el-form-item>
        </el-form>
        <div class="view-button">
          <el-button
            v-if="But.List.power()"
            type="primary"
            @click="searchzin"
            size="mini"
            class="serach"
            >查询</el-button
          >
          <span class="view-button-rest" @click="reset">重置筛选条件</span>
          <div class="int_view">
            <div></div>
            <div>
              <el-button v-if="But.Export.power()" size="mini" @click="exporT()"
                >导出</el-button
              >
              <el-button
                v-if="But.Sort.power()"
                type="primary"
                size="mini"
                @click="sortClass"
                >分类排序</el-button
              >
              <el-button
                v-if="But.Add.power()"
                type="primary"
                @click="addclassification"
                size="mini"
                >添加分类</el-button
              >
            </div>
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        size="mini"
        highlight-current-row
      >
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod"
        ></el-table-column>
        <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
        <el-table-column prop="presellCount" label="菜品数量"></el-table-column>
        <el-table-column
          prop="displayOrder"
          label="移动端显示顺序"
          width="120"
        ></el-table-column>
        <el-table-column prop="categoryStatus" label="分类状态">
          <template slot-scope="scope">{{
            equipmentstatus[scope.row.categoryStatus]
          }}</template>
        </el-table-column>
        <el-table-column
          prop="createTm"
          label="添加时间"
          width="150"
        ></el-table-column>
        <el-table-column prop="categoryRemark" label="备注"></el-table-column>
        <el-table-column prop="sourceIp" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="But.Edit.power()"
              type="text"
              @click="edit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
        <el-table-column prop="sourceIp" label="禁用/启用">
          <template slot-scope="scope">
            <el-switch
              v-if="But.SwitchAble.power()"
              v-model="scope.row.categoryStatus"
              :active-value="1"
              :inactive-value="0"
              @change="change($event, scope.row.categoryId)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>

      <div class="view-page">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page.pageNo"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="page.total"
          prev-text="上一页"
          next-text="下一页"
        ></el-pagination>
      </div>

      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="450px"
        :close-on-click-modal="false"
      >
        <el-form
          :inline="true"
          :model="formzine"
          class="demo-form-inline"
          size="mini"
          ref="formzine"
          :rules="rules"
        >
          <el-form-item label="菜品分类:" prop="categoryName">
            <el-input v-model.trim="formzine.categoryName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="移动端顺序:" prop="displayOrder">
          <el-input v-model.trim="formzine.displayOrder"></el-input>
          </el-form-item>-->
          <el-form-item label="分类备注" prop="categoryRemark">
            <el-input
              type="textarea"
              v-model.trim="formzine.categoryRemark"
              style="width: 200px; height: 57px"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="onSubmit" size="mini"
            >保存</el-button
          >
          <el-button @click="dialogVisible = false" size="mini"
            >取 消</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="分类排序"
        :visible.sync="sortDialog"
        width="400px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="beforeClose"
      >
        <sortList
          ref="sortList"
          v-if="sortDialog"
          @load="search"
          @close="sortDialog = false"
        />
      </el-dialog>
      <export-file
        URL="/order-admin/w/disher/category/export/excel"
        ref="File"
      ></export-file>
    </div>
  </div>
</template>

<script>
import { baseurl } from "../../../../base.js";
import sortList from "./sortList";
export default {
  components: { sortList },
  created() {
    this.loadlist();
  },
  data() {
    var validatePass2 = (rule, value, callback) => {
      let rez = /^([0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("最多支持两位正整数"));
      }
      callback();
    };
    return {
      title: "添加分类",
      loading: false,
      sortDialog: false,
      But: window.UserPower.variety.classification.viewInfo,
      baseurl: baseurl,
      formzine: {
        categoryName: "",
        displayOrder: "",
        categoryRemark: "",
      },
      equipmentstatus: {
        1: "启用",
        0: "停用",
      },
      rules: {
        categoryName: [
          { required: true, message: "不能为空", trigger: "blur" },
          {
            min: 1,
            max: 5,
            message: "最多5个字",
            trigger: "blur",
          },
        ],
        displayOrder: [{ validator: validatePass2, trigger: "blur" }],
        categoryRemark: [
          {
            min: 1,
            max: 20,
            message: "最多支持20个字",
            trigger: "blur",
          },
        ],
      },
      dialogVisible: false,
      beforeClose: (done)=> {
        if (this.$refs.sortList.getCloseStatus()) {
          done();
        }
      },
      formInline: {
        categoryId: "",
        categoryStatus: "",
        createTmStartStart: "",
        displayOrder: "",
        createTmStartEnd: "",
        value1: null,
        authToken: localStorage.getItem("tokenIdzin"),
      },
      equipmentlist: [],
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    sortClass() {
      this.sortDialog = true;
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    exporT() {
      this.$refs.File.export({ ...this.formInline });
    },
    change(indexzin, categoryId) {
      let inner = indexzin == 0 ? "停用" : "启用";
      this.loading = true;
      this.$http
        .post(
          `/order-admin/w/disher/category/modify/status/single/${categoryId}/${indexzin}`,
          {}
        )
        .then((data) => {
          this.loading = false;
          this.$message({
            message: `${inner}成功`,
            type: "success",
          });
          this.search();
        })
        .catch((error) => {
          this.loading = false;
          this.search();
        });
    },
    edit(inner) {
      this.dialogVisible = true;
      this.title = "编辑分类";
      this.$refs["formzine"] && this.$refs["formzine"].resetFields();
      this.$nextTick((_) => {
        this.formzine.categoryRemark = inner.categoryRemark;
        this.formzine.displayOrder = inner.displayOrder;
        this.formzine.categoryName = inner.categoryName;
        this.formzine.categoryId = inner.categoryId;
      });
    },
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/disher/category/s/all", {})
        .then((res) => {
          this.loading = false;
          this.equipmentlist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.createTmStartStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.createTmStartEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/disher/category/s/page", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.records;
          for (let i in this.tableData) {
            this.tableData[i].categoryStatus = +this.tableData[i]
              .categoryStatus;
          }
          this.page.total = res.data.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
    },
    addclassification() {
      // for (let i in this.formzine) {
      //   this.formzine[i] = "";
      // }

      this.dialogVisible = true;
      this.title = "添加分类";
      this.$nextTick((_) => {
        this.$refs["formzine"].resetFields();
      });
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    onSubmit() {
      let url =
        this.title == "添加分类"
          ? "/order-admin/w/disher/category/add"
          : "/order-admin/w/disher/category/modify";
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post(`${url}`, {
              ...this.formzine,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                message: `${this.title}成功`,
                type: "success",
              });
              this.dialogVisible = false;
              this.loadlist();
              this.search();
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.view {
  position: relative;
}
</style>


