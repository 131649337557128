<template>
  <div v-loading="loading">
    <div class="view-contion">
      <div class="view-search">
        <div class="hr_title">推荐信息</div>
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          style="padding-top:14px;"
        >
          <el-form-item label="推荐名称">
            <el-input v-model="formInline.recommendName" style="width:258px;" maxlength="20"></el-input>
          </el-form-item>
        </el-form>
        <div class="hr_title">推荐活动菜品</div>
        <div class="int_view">
          <div></div>
          <div>
            <el-button v-if="But.Add.power()" type="primary" @click="viewall" size="mini">添加菜品</el-button>
          </div>
        </div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          size="mini"
          highlight-current-row
        >
          <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
          <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
          <el-table-column prop="thumbnailUrl" label="菜品图片">
            <template slot-scope="scope">
              <el-popover placement="right" title trigger="hover">
                <img :src="scope.row.previewUrl" style="width:150px;height:150px;" />
                <img
                  slot="reference"
                  :src="scope.row.previewUrl"
                  :alt="scope.row.previewUrl"
                  style="width:50px;height:50px"
                />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].priceType" label="计价方式">
            <template
              slot-scope="scope"
            >{{options1[scope.row.disherUnitPriceSearchVoList[0].priceType]}}</template>
          </el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].price" label="单价">
            <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].price|money}}</template>
          </el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].memberPrice" label="会员价">
            <template
              slot-scope="scope"
            >{{scope.row.disherUnitPriceSearchVoList[0].memberPrice|money}}</template>
          </el-table-column>
          <el-table-column prop="sourceIp" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="dele(scope.$index,tableData)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="footer">
      <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
    </div>
    <el-dialog
      title="选择菜品"
      :visible.sync="dialogVisible"
      width="820px"
      :close-on-click-modal="false"
    >
      <view-info class="h100" v-if="dialogVisible" @close="close" :arr="tableData"></view-info>
    </el-dialog>
  </div>
</template>

<script>
import viewInfo from "./details";
export default {
  components: {
    viewInfo
  },
  created() {
    this.search();
  },
  data() {
    return {
      But: window.UserPower.variety.Recommended,
      dialogVisible: false,
      formInline: {
        recommendName: ""
      },
      tableData: [],
      disherIdList: [],
      tableDatalist: [],
      options1: {
        0: "常规计价",
        1: "称重计价",
        2: "份量计价",
        3: "时价"
      },
      loading: false
    };
  },
  methods: {
    close(val) {
      this.dialogVisible = false;
      for (let i in val) {
        this.tableData.push(val[i]);
      }
    },
    viewall() {
      this.dialogVisible = true;
    },
    indexMethod(index) {
      return index + 1;
    },
    dele(index, row) {
      row.splice(index, 1);
      this.$message({
        message: "删除成功",
        type: "success"
      });
    },
    onSubmit() {
      if (this.formInline.recommendName) {
        let disherIdList = [];
        for (let i in this.tableData) {
          disherIdList.push(this.tableData[i].dishersId);
        }
        this.loading = true;
        this.$http
          .post("/order-admin/w/disher/recommend/save", {
            disherIdList: disherIdList,
            recommendName: this.formInline.recommendName
          })
          .then(res => {
            this.loading = false;
            this.$message({
              message: "保存成功",
              type: "success"
            });
            this.search();
          })
          .catch(_ => {
            this.loading = false;
          });
      } else {
        this.$message({
          message: "请填写推荐菜品名称",
          type: "warning"
        });
      }
    },
    search() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/disher/recommend/detail", {
          supportWebp: localStorage.getItem("supportWebp")
        })
        .then(res => {
          this.loading = false;
          this.tableData = res.data.dishersList;
          this.formInline.recommendName = res.data.recommendName;
        })
        .catch(_ => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.view-contion {
  height: calc(100% - 60px);
  margin-bottom: 60px;
}
.hr_title {
  margin-bottom: 15px;
  color: #333333;
  border-bottom: 1px solid #dcdee0;
  height: 30px;
  font-size: 14px;
  margin-left: 20px;
}
.footer {
  width: 100%;
  position: fixed;
  height: 60px;
  bottom: 0px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>


