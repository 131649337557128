<template>
  <div v-loading="loading">
    <div class="box_zin">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        size="mini"
      >
        <div class="hr_title">打折活动</div>
        <div v-if="flag">
          <el-form-item label="活动名称" prop="activityName">
            <el-input class="input-width" placeholder="最多10个字符" v-model="ruleForm.activityName"></el-input>
          </el-form-item>
          <el-form-item label="活动时间" prop="value1">
            <el-date-picker
              v-model="ruleForm.value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="expireTimeOption"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="全场折扣" prop="discount">
            <el-input class="input-width" placeholder="大于0小于1" v-model="ruleForm.discount">
              <template slot="prepend">折</template>
            </el-input>
            <div class="input-tishi">实例:88折，填写0.88</div>
          </el-form-item>
        </div>
        <div v-if="!flag">
          <el-form-item label="活动名称" prop="activityName">
            <span>{{ruleForm.activityName}}</span>
          </el-form-item>
          <el-form-item label="活动时间" prop="activityBeginTm">
            <span>
              {{ruleForm.activityBeginTm}}
              ~{{ruleForm.activityEndTm}}
            </span>
          </el-form-item>
          <el-form-item label="全场折扣" v-if="!flag">
            <span>{{ruleForm.discount}}</span>
            <div class="input-tishi">实例:88折，填写0.88</div>
          </el-form-item>
        </div>
        <div class="hr_title">活动商品</div>
        <div class="view-button" style="margin-bottom:20px">
          <el-button type="primary" @click="add" size="mini">添加菜品</el-button>
        </div>
      </el-form>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%;"
        size="mini"
        highlight-current-row
      >
        <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
        <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
        <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
        <el-table-column label="计价方式">
          <template
            slot-scope="scope"
          >{{options1[scope.row.disherUnitPriceSearchVoList[0].priceType]}}</template>
        </el-table-column>
        <el-table-column prop="disherUnitPriceSearchVoList[0].price" label="单价">
          <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].price|money}}</template>
        </el-table-column>
        <el-table-column prop="disherUnitPriceSearchVoList[0].memberPrice" label="会员价">
          <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].memberPrice|money}}</template>
        </el-table-column>
        <el-table-column prop="sourceIp" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="dele(scope.$index,tableData)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
      <el-button @click="closezin" size="small">取消</el-button>
    </div>

    <el-dialog
      title="选择菜品"
      :visible.sync="dialogVisible"
      width="820px"
      :close-on-click-modal="false"
    >
      <view-info class="h100" v-if="dialogVisible" @close="close" :arr="tableData"></view-info>
    </el-dialog>
  </div>
</template>

<script>
import viewInfo from "../../variety/Recommended/details";
export default {
  components: {
    viewInfo
  },
  created() {
    this.ruleForm.disherIds = [];
    if (this.$route.query.id) {
      this.load();
    }
  },
  data() {
    var numall = (rule, value, callback) => {
      // let rez = /^0\.[0-9][1-9]$/;
      let rez = /^0\.([0-9]){1,2}$/;
      if (!rez.test(value)) {
        return callback(new Error("请输入大于0小于1的小数"));
      }
      callback();
    };
    return {
      expireTimeOption: {
        disabledDate(date) {
         return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        }
      },
      loading: false,
      options1: {
        0: "常规计价",
        1: "称重计价",
        2: "份量计价",
        3: "时价"
      },
      dialogVisible: false,
      ruleForm: {
        activityName: "",
        value1: "",
        activityBeginTm: "",
        activityEndTm: "",
        discount: "",
        disherIds: []
      },
      rules: {
        activityName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 1, max: 10, message: "支持1—10个字", trigger: "blur" }
        ],
        discount: [
          { validator: numall, trigger: "blur" },
          { required: true, message: "请输入折扣", trigger: "blur" }
        ],
        value1: [{ required: true, message: "请选择活动日期", trigger: "blur" }]
      },
      tableData: [],
      flag: true
    };
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/activity/detail`, {
          activityId: this.$route.query.id
        })
        .then(res => {
          this.loading = false;
          let data = res.data;
          this.ruleForm.activityName = data.activityName;
          this.ruleForm.activityBeginTm = data.activityBeginTm;
          this.ruleForm.activityEndTm = data.activityEndTm;
          this.ruleForm.activityId = data.activityId;
          this.tableData = data.dishersInfos;
          this.ruleForm.discount = data.discount / 100;
          this.flag = false;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    dele(index, row) {
      row.splice(index, 1);
      this.$message({
        message: "删除成功",
        type: "success"
      });
    },
    close(val) {
      this.dialogVisible = false;
      for (let i in val) {
        this.tableData.push(val[i]);
      }
    },
    add() {
      this.dialogVisible = true;
    },
    indexMethod(index) {
      return index + 1;
    },
    onSubmit() {
      for (let i in this.tableData) {
        this.ruleForm.disherIds.push(this.tableData[i].dishersId);
      }
      if (this.flag) {
        this.ruleForm.activityBeginTm = this.ruleForm.value1[0]
          ? this.ruleForm.value1[0] + " 00:00:00"
          : "";
        this.ruleForm.activityEndTm = this.ruleForm.value1[1]
          ? this.ruleForm.value1[1] + " 23:59:59"
          : "";
      }
      let data = { ...this.ruleForm };
      data.discount = +(data.discount * 100).toFixed(2);
      let url = this.flag
        ? "/order-admin/w/activity/add/discount"
        : "/order-admin/w/activity/modify";
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.loading = true;
          this.$http
            .post(`${url}`, { ...data })
            .then(res => {
              this.loading = false;
              this.$message({
                message: "添加成功",
                type: "success"
              });
              this.$router.push({
                path: "Preferential",
                query: { type: 1 }
              });
            })
            .catch(error => {
              this.loading = false;
            });
        }
      });
    },
    closezin() {
      this.$router.push("/Preferential");
    }
  }
};
</script>

<style scoped>
.box_zin {
  height: calc(100% - 60px);
  margin-bottom: 60px;
}
.input-width {
  width: 170px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.hr_title {
  margin-bottom: 20px;
}
</style>
