<template>
  <div v-loading="loading">
    <el-form :inline="true" :model="formzinernil" class="demo-form-inline" ref="formzinernil" :rules="rules">
      <el-form-item label="菜品重量:" prop="disherUnitValue">
        <el-input style="width:150px" v-model="formzinernil.disherUnitValue">
          <template slot="append">{{disheObj.priceUnitName}}</template>
        </el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: right;">
      <el-button type="primary" @click="onSubmit" size="mini">确定</el-button>
      <el-button @click="onClose" size="mini">取消</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["disheObj"],
  created() {
    for (let key in this.formzinernil) {
      this.formzinernil[key] = this.disheObj[key];
    }
  },
  data() {
    return {
      loading: false,
      formzinernil: {
        disherUnitValue: "",
        orderDishesId: "",
        dishesId: "",
        orderNo: "",
      },
      rules: {
        disherUnitValue: [
          { required: true, message: "必填项", trigger: "blur" },
          {
            validator: (r, v, cb) => {
              if (v === "") return cb();
              if (!/^\d{1,2}(\.\d)?$/.test(v)) {
                return cb(new Error("范围；0.1~99.9"));
              }
              if (+v == 0) {
                return cb(new Error("范围；0.1~99.9"));
              }
              return cb();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    onSubmit() {
      this.$refs.formzinernil.validate(async (valid) => {
        if (!valid) return;
        this.loading = true;
        try {
          await this.$http.post(
            "/cashier-api/api/dishes/updateCurrentDishesWeight",
            this.formzinernil
          );
          this.onClose();
          this.$emit("load");
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } finally {
          this.loading = false;
        }
      });
    },
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
</style>