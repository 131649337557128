<template>
  <div>
    <el-form
      :label-position="left"
      :inline="true"
      size="mini"
      label-width="80px"
      :model="formInlinezin"
      class="demo-form-inline"
      :rules="rules"
      ref="formInlinezin"
    >
      <el-row>
        <el-col :span="24" style="margin-left:20px">
          <el-form-item label="手机号" prop="mobileNo" style="margin-top:16px">
            <el-input v-model.trim="formInlinezin.mobileNo" clearable maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smsValidCode">
            <el-input
              v-model.trim="formInlinezin.smsValidCode"
              clearable
              style="width:120px;margin-right:5px;"
            ></el-input>
            <div v-show="showzin" class="verification">
              <span @click="getCode" style="cursor:pointer;">{{code}}</span>
            </div>
            <div
              v-show="showzin!=true"
              class="verification"
              style="background-color:#bfbcbc;color:#fff;"
            >
              <span>{{count}}秒</span>
            </div>
          </el-form-item>
          <el-form-item label="新密码" prop="newPwd">
            <el-input
              type="password"
              v-model.trim="formInlinezin.newPwd"
              clearable
              maxlength="20"
              placeholder="8-20位,支持数字和字母"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="newConfirmPwd">
            <el-input
              type="password"
              v-model.trim="formInlinezin.newConfirmPwd "
              clearable
              maxlength="20"
              placeholder="8-20位,支持数字和字母"
            ></el-input>
          </el-form-item>
          <el-form-item style="width:100%;text-align:center">
            <el-button type="primary" @click="submitForm('formInlinezin')">确认</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import axios from "../../../http.js";
import { JSEncrypt } from "jsencrypt";
export default {
  props: { username: String },
  data() {
    var validatePass = (rule, value, callback) => {
      let rez = /^(?![^A-z]+$)(?!\D+$)[A-z\d]{8,20}$/;
      if (!rez.test(value)) {
        return callback(new Error("8-20位,支持数字和字母"));
      }
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.formInlinezin.newConfirmPwd !== "") {
          this.$refs.formInlinezin.validateField("newConfirmPwd");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formInlinezin.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
      let rez = /^(?![^A-z]+$)(?!\D+$)[A-z\d]{8,20}$/;
      if (!rez.test(value)) {
        return callback(new Error("8-20位,支持数字和字母"));
      }
    };
    return {
      formInlinezin: {
        mobileNo: "",
        smsValidCode: "",
        newConfirmPwd: "",
        newPwd: ""
      },
      showzin: true,
      count: "",
      timer: null,
      code: "发送验证码",
      left: "left",
      rules: {
        smsValidCode: [
          { required: true, message: "请输入验证码", trigger: "blur" }
        ],
        mobileNo: [
          {
            required: true,
            message: "请填写手机号",
            trigger: "blur"
          }
        ],
        newPwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur"
          },
          { validator: validatePass, trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "8-20位,支持数字和字母",
            trigger: "blur"
          }
        ],
        newConfirmPwd: [
          {
            required: true,
            message: "请确认密码",
            trigger: "blur"
          },
          { validator: validatePass2, trigger: "blur" },
          {
            min: 8,
            max: 20,
            message: "8-20位,支持数字和字母",
            trigger: "blur"
          }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.fullscreenLoading = true;

          const enc = new JSEncrypt();
          enc.setPublicKey(
            "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDHostYAjUJLiwehBWnFesYUorfMICgXLcAPC3MApRyp/Qif5DvpodtVJSyMw5ztOeKaVnJ4aYmPQzcyjSoeW3NLY+3n9l4Yf5a4S7/U4jru2Sl1k6JPLlax3W8Oozb1SI4S3y0hhWf7ujO1fh15DaDH5BDIwaLNbvTQFeo9V1bswIDAQAB"
          );
          let rasPw = enc.encrypt(this.formInlinezin.newPwd);
          this.$http
            .post("/order-admin/w/auth/passwd-forget", {
              phone: this.formInlinezin.mobileNo,
              newPassword: rasPw,
              smsVerifyCode: this.formInlinezin.smsValidCode
            })
            .then(data => {
              this.$message.success("找回密码成功");
              this.$emit("close");
              this.changepass = false;
              this.fullscreenLoading = false;
            })
            .catch(error => {
              this.fullscreenLoading = false;
            });
        } else {
         
          return false;
        }
      });
    },
    getCode() {
      if (this.formInlinezin.mobileNo == "") {
        this.$message.error("请填写手机号");
      } else if (!/^1[0-9]\d{9}$/.test(this.formInlinezin.mobileNo)) {
        this.$message.error("请填写正确的手机号");
        return false;
      } else {
        axios.defaults.headers.post["jwt-ov3"] = "";
        this.$http
          .post(`/order-admin/w/sms-forget`, {
            phone: this.formInlinezin.mobileNo,
            userLoginName: this.username
          })
          .then(data => {
            this.fullscreenLoading = false;
            const TIME_COUNT = 60;
            if (!this.timer) {
              this.count = TIME_COUNT;
              this.showzin = false;
              this.timer = setInterval(() => {
                if (this.count > 0 && this.count <= TIME_COUNT) {
                  this.count--;
                } else {
                  this.showzin = true;
                  this.code = "重新发送";
                  clearInterval(this.timer);
                  this.timer = null;
                }
              }, 1000);
            }
          })
          .catch(error => {
            this.fullscreenLoading = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.verification {
  width: 80px;
  height: 30px;
  background: #409eff;
  font-size: 12px;
  border-radius: 3px;
  line-height: 30px;
  text-align: center;
  color: white;
  display: inline-block;
}
</style>