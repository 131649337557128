<template>
  <div class="cancal-container" v-show="detail.orderStatus==3" v-loading="Loading">
    <div class="cancal-content">
      <div class="cancal-loading" refs="logo"><i class="el-icon-loading"></i></div>
      <div>顾客正在扫码支付，请稍候…</div>
      <div class="cancal-btn" @click="cancalPayFn">取消支付</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["detail"],
  created() {},
  data() {
    return { Loading: false };
  },
  watch: {
    detail(v) {
  
      if (v.orderStatus == 3) {
        this.$emit("colseAllDialog");
      }
    },
  },
  methods: {
    cancalPayFn() {
      this.$confirm("顾客正在进行扫码支付，确认强制取消支付？", "取消支付", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.Loading = true;
        this.$http
          .post("/cashier-api/api/orderNew/cancelOrder", {
            orderNo: this.detail.orderNo,
          })
          .then(
            () => {
              this.$message({
                message: "取消支付成功！",
                type: "success",
              });
              this.$emit("load");
              this.Loading = false;
            },
            () => {
              this.Loading = false;
            }
          );
      });
    },
  },
};
</script>

<style scoped>
.cancal-container {
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  color: #ffffff;
  z-index: 10;
}
.cancal-content {
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  width: 300px;
  padding: 27px 0;
}
.cancal-loading {
  font-size: 46px;
  margin-bottom: 20px;
}
.cancal-btn {
  color: #0091ff;
  margin-top: 12px;
  cursor: pointer;
  transition: color 0.2s;
}
.cancal-btn:hover {
  color: #41739c;
}
</style>