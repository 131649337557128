<template>
  <div>
    <div class="info_align">
      <div class="info_left">
        <div>
          <p
            @click="fenleiall('', '')"
            v-bind:class="{ class1: '' === classtab }"
            style="cursor: pointer"
          >
            所有分类
          </p>
          <div class="zinfo" v-for="(i, index) in tablist" :key="index">
            <p
              @click="fenleiall(i.categoryId, index)"
              v-bind:class="{ class1: index === classtab }"
            >
              {{ i.categoryName }}
            </p>
          </div>
        </div>
      </div>
      <div class="info_right">
        <div class="searchGoodsBox">
          <el-input
            placeholder="综合搜索"
            v-model="ruleForm.dishersName"
            size="mini"
            clearable
          >
            <i
              slot="suffix"
              class="el-icon-search el-input__icon"
              @click="search(1)"
            ></i>
          </el-input>
        </div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="margin-top: 10px"
          size="mini"
          highlight-current-row
          @selection-change="handleSelectionChange"
          :row-key="getRowKey"
        >
          <el-table-column
            type="selection"
            width="55"
            :reserve-selection="true"
            :selectable="isChecked"
          ></el-table-column>
          <el-table-column
            label="序号"
            type="index"
            :index="indexMethod"
          ></el-table-column>
          <el-table-column
            prop="dishersName"
            label="菜品名称"
          ></el-table-column>
          <el-table-column prop="thumbnailUrl" label="菜品图片">
            <template slot-scope="scope">
              <el-popover placement="right" title trigger="hover">
                <img
                  :src="scope.row.previewUrl"
                  style="width: 150px; height: 150px"
                />
                <img
                  slot="reference"
                  :src="scope.row.previewUrl"
                  :alt="scope.row.previewUrl"
                  style="width: 50px; height: 50px"
                />
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column
            prop="categoryName"
            label="菜品分类"
          ></el-table-column>
          <el-table-column
            prop="disherUnitPriceSearchVoList[0].priceType"
            label="计价方式"
          >
            <template slot-scope="scope">{{
              options1[scope.row.disherUnitPriceSearchVoList[0].priceType]
            }}</template>
          </el-table-column>
          <el-table-column
            prop="disherUnitPriceSearchVoList[0].price"
            label="单价"
          >
            <template slot-scope="scope">{{
              scope.row.disherUnitPriceSearchVoList[0].price | money
            }}</template>
          </el-table-column>
          <el-table-column
            prop="disherUnitPriceSearchVoList[0].memberPrice"
            label="会员价"
          >
            <template slot-scope="scope">{{
              scope.row.disherUnitPriceSearchVoList[0].memberPrice | money
            }}</template>
          </el-table-column>
        </el-table>
        <div class="view-page">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page="page.pageNo"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="page.total"
            prev-text="上一页"
            next-text="下一页"
          ></el-pagination>
        </div>
      </div>
    </div>
    <div class="footerzin">
      <div>
        已选中
        <span>{{ multipleSelection.length }}</span
        >个
      </div>
      <el-button type="primary" size="mini" @click="submited">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: { arr: Array },
  created() {
    this.search();
  },
  data() {
    return {
      ruleForm: {
        categoryId: "",
        dishersName: "",
        isContainTimeDishes: "1",
      },
      tablist: [],
      options1: {
        0: "常规计价",
        1: "称重计价",
        2: "份量计价",
        3: "时价",
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      multipleSelection: [],
      classtab: "",
    };
  },
  methods: {
    isChecked(row, index) {
      return row.depotStatus == 1;
    },
    getRowKey(row) {
      return row.dishersId;
    },
    selectable(row, index) {
      if (
        this.arr.some((el) => {
          return el.dishersId === row.dishersId;
        })
      ) {
        return false;
      } else {
        return true;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    submited() {
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "请选择要添加的菜品",
          type: "warning",
        });
      } else {
        this.$emit("close", this.multipleSelection);
      }
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    fenleiall(id, index) {
      this.classtab = index;
      this.ruleForm.categoryId = id;
      this.search(1);
    },
    search(pageNum) {
      this.ruleForm.categoryId = this.ruleForm.dishersName
        ? ""
        : this.ruleForm.categoryId;
      this.page.pageNo = pageNum ? pageNum : this.page.pageNo;
      this.$http
        .post("/order-admin/w/disher/s/page", {
          ...this.ruleForm,
          ...this.page,
        })
        .then((res) => {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
          if (this.ruleForm.dishersName) {
            this.page.pageNo = 1;
          }
        });
      this.$http
        .post("/order-admin/w/disher/category/s/all", {})
        .then((res) => {
          this.tablist = res.data;
        });
    },
  },
};
</script>

<style scoped>
.searchGoodsBox {
  width: 193px;
  display: flex;
  justify-content: right;
  margin-left: auto;
}
.info_align {
  display: flex;
  justify-content: flex-start;
}
.info_left {
  width: 15%;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  border-right: 1px solid #e0e2e3;
  padding-top: 50px;
}
.info_left div {
  text-align: left;
}
.info_right {
  width: 85%;
  padding-top: 10px;
  margin-right: 15px;
}
.search {
  width: 190px;
  margin: 20px 0;
  height: 30px;
  float: right;
}
.footerzin {
  width: 100%;
  height: 60px;
  font-size: 12px;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.footerzin div {
  margin-right: 10px;
}
.footerzin div span {
  color: #108af9;
}
.zinfo p {
  margin: 4px 0;
  cursor: pointer;
}
.class1 {
  color: #108af9;
  cursor: pointer;
}
</style>
