import axios from "@/http.js";
let shopingCard = {
    state() {
        return {
            shopingLoading: false,
            shopingCardList: [],
            shopingCardIds: [],
            shopingCardActive: null,
            shopingCardOrder: {},
            shopingCardVip: false,
        }
    },
    mutations: {
        SetShopingCardActive(state, data) {
            state.shopingCardActive = data;
        },
        setShopingLoading(state, data) {
            state.shopingLoading = data;
        },
        setShopingCard(state, data) {
            state.shopingCardIds = data.addOrderDishes.map(v => v.dishesId)
            state.shopingCardList = data.addOrderDishes;
            data.addOrderDishes = null;
            state.shopingCardOrder = data;

        },
        setShopingCardVip(state, data) {
            state.shopingCardVip = data;
        },
    },
    actions: {
        async getShopingCard({ commit }, option) {
            try {
                commit("setShopingLoading", true)
                let { data } = await axios.post("/cashier-api/api/orderNew/getOrderAndDishesList", option);
                commit("setShopingCard", data)
                commit("setShopingLoading", false);
            } catch (err) {
                commit("setShopingLoading", false)
            }

        },
    }
}
export default shopingCard;