<template>
  <div class="shop-container" v-loading="loading||Loading">
    <div class="shop-header">
      <div v-show="orderMode==1">
        <span>{{orderInfo.tableRegionName}}-{{orderInfo.tableName}}</span>
        <span>{{orderInfo.dinersCount}}/{{orderInfo.seatingCapacity}}座</span>
      </div>
      <div>
        <span><span class="c999999">下单员：</span>{{orderInfo.operatorName}}</span>
        <span>{{orderInfo.openTableTimeStr}}</span>
      </div>
    </div>
    <div class="shop-body">
      <el-scrollbar style="height:100%">
        <div class="shop-li" :class="{active:item===indexActive,time:item.priceType==2||item.priceType==5}" @click="selectDisher(item)" v-for="item in disherData" :key="item.orderDishesId">
          <div class="li-details">
            <div class="details-name">
              <span class="cFF3B3B" v-if="item.priceType==5">(称)</span>
              <span class="cFF3B3B" v-if="item.priceType==2">(时)</span>
              <span class="cFF3B3B" v-if="item.priceType==3">(赠)</span>
              <span class="cFF3B3B" v-if="item.priceType==4">(特)</span>
              {{item.dishesName}}
            </div>
            <div class="details-size">
              <span class="c999999">{{[item.priceUnitName,item.tasteName]|filterNullToString}}</span>
              <span class="details-remark c108AF9" @click.stop="addRemark(item)">【{{item.dishersRemark.length==0?"添加":"查看"}}备注】</span>
            </div>
          </div>
          <div class="li-num">
            <span>×{{item.selectCount}}</span>
          </div>
          <img class="time-logo" v-if="item.dishesPriceType==3" src="@/assets/image/img_shijiacai_wutu.png" alt="">
          <img class="time-logo" v-if="item.dishesPriceType==1" src="@/assets/image/img_chengzhong_wutu.png" alt="">
          <div class="li-price c108AF9" @click="changePrice(item)" v-if="item.priceType==2&&item.totalAmount==0"> 请改价
          </div>
          <div class="li-price c108AF9" @click="editWeight(item)" v-if="item.dishesPriceType==1&&item.disherUnitValue==0"> 请称重
          </div>
          <div class="li-price" v-if="item.totalAmount || (item.dishesPriceType==1&&item.disherUnitValue!=0)">
            <div class="cFF3B3B">￥{{item.totalAmount|money}}</div>
            <div v-if="item.dishesPriceType==1">
              <span v-if="item.disherUnitValue">
                {{item.disherUnitValue}}{{ item.priceUnitName }}
              </span>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div class="shop-money">
      <span><span class="c999999">合计：{{disherData|disherDataLength}}</span></span>
      <span class="shop-money-sum cFF3B3B"><span class="c999999">总计：</span>￥{{orderInfo.orderOriginAmount|money}}</span>
    </div>
    <div class="shop-btn" v-if="disherData.length!=0">
      <div class="btn btn-clear clear-order" @click="removeDishesList"> 清空菜单 </div>
      <div class="btn btn-ok ok-order" @click="mergeDishesOrder"> 下单 </div>
    </div>
    <el-dialog title="修改菜品重量" :visible.sync="dialogWeightVailt" width="450px" append-to-body :close-on-click-modal="false" :destroy-on-close="true">
      <editWeight :disheObj="disheObj" @close="colseEditWeight" @load="editWeightLoad" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import mqtt, { getMerchantInfoVo } from "../../mqtt.js";
import editWeight from "../editWeight.vue";
export default {
  props: ["orderNo", "tableId", "orderMode"],
  components: { editWeight },
  async created() {
    await this.getShopingCard({ orderNo: this.orderNo });
    if (this.tableId) {
      let parentOrderNo =
        this.orderInfo.parentOrderNo || this.orderInfo.orderNo;
      this.MQTT = mqtt({
        themeNo: getMerchantInfoVo().merchantId + "-" + parentOrderNo,
        messageFn: (body) => {
          if (body.indexPage == 10) {
            return this.$router.replace({
              path: "/selectMenu",
              query: {
                orderNo: this.orderNo,
                orderMode: this.orderMode,
                tableId: body.tableId,
              },
            });
          }
          if (body.indexPage == 8 || body.indexPage == 9) {
            return this.$router.replace({
              path: "/selectTable",
            });
          }
          if (body.indexPage == 4 || body.indexPage == 5) {
            let data = {
              orderNo: parentOrderNo,
            };
            return this.$router.replace({
              path: "/toPayment",
              query: {
                ...data,
                tableId: this.tableId,
                orderMode: this.orderMode,
              },
            });
          }
          this.getShopingCard({ orderNo: this.orderNo });
        },
      });
    }
  },
  beforeDestroy() {
    this.MQTT && this.MQTT.end();
  },
  filters: {
    filterNullToString(val) {
      return val.filter((v) => !!v).join("+");
    },
    disherDataLength(v) {
      let leng = 0;
      if (!v) return leng;
      v.forEach((v) => {
        leng += v.selectCount;
      });
      return leng;
    },
  },
  computed: {
    ...mapState({
      disherData: (state) => state.shopingCard.shopingCardList,
      indexActive: (state) => state.shopingCard.shopingCardActive,
      orderInfo: (state) => state.shopingCard.shopingCardOrder,
      loading: (state) => state.shopingCard.shopingLoading,
    }),
  },
  data() {
    return {
      Loading: false,
      MQTT: null,
      dialogWeightVailt: false,
      disheObj: null,
    };
  },
  watch: {
    disherData(nv) {
      this.restActiveFn(nv);
    },
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    ...mapMutations(["SetShopingCardActive"]),
    editWeight(item) {
      this.disheObj = item;
      this.dialogWeightVailt = true;
    },
    editWeightLoad() {
      this.getShopingCard({ orderNo: this.orderNo });
    },
    colseEditWeight() {
      this.dialogWeightVailt = false;
    },
    async changePrice(item) {
      if (item.priceType != 2) return;
      let inputValue = "";
      if (item.price) {
        inputValue = (item.price / 100).toFixed(2) || "";
      }
      let { value } = await this.$prompt("请输入价格", "提示", {
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue,
        inputValidator(v) {
          let reg = /^\d{1,5}(\.\d{1,2})?$/;
          if (!reg.test(v)) return "金额只支持0~99999.99";
          if (+v === 0) return "金额只支持0~99999.99";
          return true;
        },
      });
      try {
        let data = {
          orderNo: this.orderNo,
          dishesId: item.dishesId,
          orderDishesId: item.orderDishesId,
          price: (value * 100).toFixed(0),
        };
        await this.$http.post(
          "/cashier-api/api/dishes/updateCurrentDishesPrice",
          data
        );
        this.getShopingCard({ orderNo: this.orderNo });
      } catch (e) {}
    },
    async mergeDishesOrder() {
      try {
        this.Loading = true;
        let data = {
          orderNo: this.orderInfo.parentOrderNo || this.orderInfo.orderNo,
        };
        await this.$http.post("/cashier-api/api/dishes/mergeDishesOrder", data);
        this.$router.replace({
          path: "/toPayment",
          query: { ...data, tableId: this.tableId, orderMode: this.orderMode },
        });
      } finally {
        this.Loading = false;
      }
    },
    restActiveFn(data) {
      if (this.indexActive) {
        let Status = true;
        for (let value of data) {
          if (value.orderDishesId === this.indexActive.orderDishesId) {
            this.SetShopingCardActive(value);
            Status = false;
            break;
          }
        }
        if (Status) this.SetShopingCardActive(null);
      } else {
        this.SetShopingCardActive(null);
      }
    },
    async removeDishesList() {
      await this.$confirm("此操作将清空菜单, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      await this.$http.post("/cashier-api/api/dishes/removeDishesList", {
        orderNo: this.orderNo,
        merchantId: localStorage.getItem("merchantId"),
      });
      this.$message({
        message: "清空菜单成功！",
        type: "success",
      });
      this.getShopingCard({ orderNo: this.orderNo });
    },
    async addRemark(item) {
      let { value } = await this.$prompt("请输入备注", "提示", {
        closeOnClickModal: false,
        inputValue: item.dishersRemark,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{0,30}$/,
        inputErrorMessage: "最多输入30个字",
      });
      let data = {
        orderNo: this.orderNo,
        orderDishesId: item.orderDishesId,
        orderDishesRemark: value,
      };
      await this.$http.post(
        "/cashier-api/api/dishes/saveOrderDishesRemark",
        data
      );
      this.$message({
        message: "保存备注成功！",
        type: "success",
      });
      this.getShopingCard({ orderNo: this.orderNo });
    },
    selectDisher(item) {
      let data = item;
      if (this.indexActive === item) {
        data = null;
      }
      this.SetShopingCardActive(data);
    },
  },
};
</script>

<style scoped src="./index.css">
</style>