<template>
  <div class="view-head h100">
    <div class="font_wzo">{{title}}</div>
  </div>
</template>

<script>
import Bus from "@/common/bus.js";
export default {
  mounted() {
    this.load();
  },
  destroyed() {
    Bus.$off("getTarget", (target) => {
      this.title = target;
    });
  },
  data() {
    return {
      loginName: localStorage.getItem("loginName"),
      title: "",
    };
  },
  methods: {
    load() {
      Bus.$on("getTarget", (target) => {
        this.title = target;
      });
    },
  },
};
</script>

<style scoped>
.font_wzo {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #666666;
}
.view-head {
  display: flex;
  align-items: center;
  background-color: #fff;
}
.view-head > div:nth-of-type(1) {
  font-size: 15px;
  letter-spacing: 2px;
  display: flex;
  align-items: flex-end;
}
.view-head > div:nth-of-type(1) span {
  border: 1px solid #e2e2e2;
  border-bottom: none;
  background-color: #fff;
  position: relative;
  top: 1px;
  padding: 10px;
  color: #8e7c7c;
}
.view-head > div:nth-of-type(2) {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 20px;
}
.view-head > div:nth-of-type(2) span {
  font-size: 15px;
}
span {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
  margin: 0 5px 0 10px;
}
img:nth-of-type(1) {
  width: 24px;
  height: 24px;
}
img:nth-of-type(2) {
  margin-right: 20px;
}
.hr {
  width: 1px;
  height: 18px;
  background: #e9e9e9;
  margin-right: 20px;
}
.setBox {
  position: absolute;
  background: white;
  width: 99px;
  height: 65px;
  font-size: 12px;
  padding-left: 20px;
  z-index: 99;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
  top: 26px;
  left: 30px;
}
.setBox p {
  margin: 10px 0;
}
</style>