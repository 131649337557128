<template>
  <div v-loading="loading">
    <div class="box_zin">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="mini">
        <div class="hr_title">满减活动</div>
        <div v-if="flag">
          <el-form-item label="活动名称" prop="activityName">
            <el-input class="input-width" placeholder="最多10个字符" v-model.trim="ruleForm.activityName"></el-input>
          </el-form-item>
          <el-form-item label="活动时间" prop="value1">
            <el-date-picker v-model="ruleForm.value1" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
              :picker-options="expireTimeOption"></el-date-picker>
          </el-form-item>
          <el-form-item label="满减设置">
            <div class="contain_f">
              <div>
                <span>起始金额</span>
                <span style="margin-left:140px">减免金额</span>
              </div>
              <div style="display:flex;" v-for="(item,key) in ruleForm.rebate" :key="key">
                <el-form-item :rules=" { validator: startM, trigger: 'blur' }" :prop="'rebate.'+key+'.startAmount'">
                  <el-input class="input-width" v-model="item.startAmount" :disabled="key!=(ruleForm.rebate.length-1)">
                    <template slot="prepend">￥</template>
                  </el-input>
                </el-form-item>
                <el-form-item :rules=" { validator: startD, trigger: 'blur' }" :prop="'rebate.'+key+'.reduceAmount'">
                  <el-input class="input-width" v-model="item.reduceAmount" style="margin-left:20px" :disabled="key!=(ruleForm.rebate.length-1)">
                    <template slot="prepend">￥</template>
                  </el-input>
                </el-form-item>
                <el-button type="text" @click="del(key)" size="mini" class="del" v-show="key&&(key==(ruleForm.rebate.length-1))">删除</el-button>
              </div>
              <!-- <el-button @click="addman" size="mini" class="btn" type="primary">增加一级优惠</el-button> -->
            </div>
          </el-form-item>
        </div>
        <div style="margin-top:10px" v-if="!flag">
          <el-form-item label="活动名称" prop="activityName">
            <span>{{ruleForm.activityName}}</span>
          </el-form-item>
          <el-form-item label="活动时间" prop="activityBeginTm">
            <span>
              {{ruleForm.activityBeginTm}}
              ~{{ruleForm.activityEndTm}}
            </span>
          </el-form-item>
          <el-form-item label="满减信息" prop="startAmount">
            <div v-for="(item,key) in ruleForm.rebate" :key="key">
              <span>满{{item.startAmount|money}}减{{item.reduceAmount|money}}</span>
            </div>
          </el-form-item>
        </div>
        <div class="hr_title">活动商品</div>
        <div class="view-button" style="margin-bottom:20px">
          <el-button type="primary" @click="add" size="mini">添加菜品</el-button>
        </div>
      </el-form>
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini" highlight-current-row>
        <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
        <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
        <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
        <el-table-column label="计价方式">
          <template slot-scope="scope">{{options1[scope.row.disherUnitPriceSearchVoList[0].priceType]}}</template>
        </el-table-column>
        <el-table-column prop="disherUnitPriceSearchVoList[0].price" label="单价">
          <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].price|money}}</template>
        </el-table-column>
        <el-table-column prop="disherUnitPriceSearchVoList[0].memberPrice" label="会员价">
          <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].memberPrice|money}}</template>
        </el-table-column>
        <el-table-column prop="sourceIp" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="dele(scope.$index,tableData)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-button type="primary" @click="onSubmit" size="small">保存</el-button>
      <el-button @click="closezin" size="small">取消</el-button>
    </div>
    <el-dialog title="选择菜品" :visible.sync="dialogVisible" width="820px" :close-on-click-modal="false">
      <view-info class="h100" v-if="dialogVisible" @close="close" :arr="tableData"></view-info>
    </el-dialog>
  </div>
</template>

<script>
import viewInfo from "../../variety/Recommended/details";
export default {
  created() {
    this.ruleForm.disherIds = [];
    if (this.$route.query.id) {
      this.load();
    }
  },
  components: {
    viewInfo,
  },
  data() {
    var numall = (rule, value, callback) => {
      let rez = /^[1-9]{1,6}((\.[0-9][1-9]){0,})$/;
      if (!rez.test(value)) {
        return callback(new Error("支持1—999999.99"));
      }
      callback();
    };
    return {
      expireTimeOption: {
        disabledDate(date) {
          return date.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      loading: false,
      flag: true,
      rebate: [],
      dialogVisible: false,
      options1: {
        0: "常规计价",
        1: "称重计价",
        2: "份量计价",
        3: "时价",
      },
      ruleForm: {
        activityName: "",
        value1: [],
        activityBeginTm: "",
        activityEndTm: "",
        disherIds: [],
        rebate: [
          {
            startAmount: "",
            reduceAmount: "",
          },
        ],
        activityId: "",
      },
      rules: {
        activityName: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { min: 1, max: 10, message: "支持1—10个字", trigger: "blur" },
        ],
        value1: [
          { required: true, message: "请选择活动时间", trigger: "blur" },
        ],
      },
      tableData: [],
      type: this.$route.query.type,
      startM: (r, v, c) => {
        let reg = /^[1-9][0-9]{0,5}(\.[0-9]{1,2})?$/;
        if (!reg.test(v)) {
          return c(new Error("支持1—999999.99"));
        }
        let arr = this.ruleForm.rebate,
          len = this.ruleForm.rebate.length;
        if (len > 1) {
          if (+arr[len - 1].startAmount <= +arr[len - 2].startAmount) {
            return c(new Error("请输入大于上一个的起始金额"));
          }
        }
        return c();
      },
      startD: (r, v, c) => {
        let reg = /^[1-9][0-9]{0,5}(\.[0-9]{1,2})?$/;
        if (!reg.test(v)) {
          return c(new Error("支持1—999999.99"));
        }
        let arr = this.ruleForm.rebate,
          len = this.ruleForm.rebate.length;

        if (len > 1) {
          if (+arr[len - 1].reduceAmount <= +arr[len - 2].reduceAmount) {
            return c(new Error("请输入大于上一个的满减金额"));
          }
        }
        if (+arr[len - 1].reduceAmount >= +arr[len - 1].startAmount) {
          return c(new Error("减免金额应小于起始金额"));
        }

        return c();
      },
    };
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/activity/detail`, {
          activityId: this.$route.query.id,
        })
        .then((res) => {
          this.loading = false;
          let data = res.data;
          this.ruleForm.rebate = data.rebateList;
          this.ruleForm.activityName = data.activityName;
          this.ruleForm.activityBeginTm = data.activityBeginTm;
          this.ruleForm.activityEndTm = data.activityEndTm;
          this.ruleForm.activityId = data.activityId;
          this.tableData = data.dishersInfos;
          this.flag = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    dele(index, row) {
      row.splice(index, 1);
      this.$message({
        message: "删除成功",
        type: "success",
      });
    },
    close(val) {
      this.dialogVisible = false;
      for (let i in val) {
        this.tableData.push(val[i]);
      }
    },
    add() {
      this.dialogVisible = true;
    },
    indexMethod(index) {
      return index + 1;
    },
    addman() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleForm.rebate.length >= 5) {
            this.$message({
              message: "最多添加5条",
              type: "warning",
            });
          } else {
            this.ruleForm.rebate.push({
              startAmount: "",
              reduceAmount: "",
            });
          }
        }
      });
    },
    del(key) {
      this.ruleForm.rebate.splice(key, 1);
    },
    onSubmit() {
      for (let i in this.tableData) {
        this.ruleForm.disherIds.push(this.tableData[i].dishersId);
      }
      if (this.flag) {
        this.ruleForm.activityBeginTm = this.ruleForm.value1[0]
          ? this.ruleForm.value1[0] + " 00:00:00"
          : "";
        this.ruleForm.activityEndTm = this.ruleForm.value1[1]
          ? this.ruleForm.value1[1] + " 23:59:59"
          : "";
      }
      let datainner = { ...this.ruleForm };
      datainner.rebate = JSON.parse(JSON.stringify(this.ruleForm.rebate));
      for (let i in datainner.rebate) {
        datainner.rebate[i].startAmount = +(
          datainner.rebate[i].startAmount * 100
        ).toFixed(2);
        datainner.rebate[i].reduceAmount = +(
          datainner.rebate[i].reduceAmount * 100
        ).toFixed(2);
      }
      let url = this.flag
        ? "/order-admin/w/activity/add/rebate"
        : "/order-admin/w/activity/modify";
      this.ruleForm.activityId = this.type == 1 ? this.$route.query.id : "";
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$http
            .post(`${url}`, datainner)
            .then((res) => {
              this.loading = false;
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.$router.push({
                path: "Preferential",
                query: { type: 1 },
              });
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
    closezin() {
      this.$router.push("/Preferential");
    },
  },
};
</script>

<style scoped>
.box_zin {
  height: calc(100% - 60px);
  margin-bottom: 60px;
}
.del {
  margin-left: 15px;
}
.input-width {
  width: 170px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.hr_title {
  margin-bottom: 20px;
}
.footer {
  width: 100%;
  position: fixed;
  height: 60px;
  bottom: 0px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 400;
}
span {
  color: #606266;
}
.contain_f {
  width: 402px;
  background: #fafafa;
  padding: 20px 20px;
}
.btn {
  background: #ffffff;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #333333;
}
</style>
