<template>
  <div class="operation-container" v-loading="loading">
    <div class="operation-div div1">
      <template v-if="indexActive&&indexActive.dishesPriceType!=1">
        <div @click="addDishes" class="f15">+</div>
        <div @click="reduceDishes" class="f15">-</div>
      </template>
      <div @click="editWeight" v-show="indexActive&&indexActive.dishesPriceType==1">修改重量</div>
      <div @click="removeOneOrderDishes">删除</div>
      <div v-show="indexActive&&indexActive.priceType==2" @click="updateCurrentDishesPrice">改价</div>
      <div @click="giveOrderDishes" v-show="indexActive&&!indexActive.hasSpecialPrice">{{indexActive&&indexActive.priceType==3?"取消":""}}赠送</div>
    </div>
    <div class="operation-div">
      <div v-if="orderInfo.parentOrderNo" @click="cancelAddDishes">放弃加菜</div>
      <div v-if="!orderInfo.parentOrderNo || orderMode==2" @click="saveOrderRemark">整单备注</div>
      <template v-if="orderMode==1">
        <div @click="changeTable">换桌</div>
        <div @click="clearTable">清台</div>
      </template>
    </div>
    <el-dialog title="修改菜品重量" :visible.sync="dialogWeightVailt" width="450px" append-to-body :close-on-click-modal="false" :destroy-on-close="true">
      <editWeight v-if="dialogWeightVailt" :disheObj="disheObj" @close="colseEditWeight" @load="editWeightLoad" />
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import editWeight from "../editWeight.vue";
export default {
  props: ["orderNo", "tableId", "orderMode"],
  components: { editWeight },
  computed: mapState({
    indexActive: (state) => state.shopingCard.shopingCardActive,
    orderInfo: (state) => state.shopingCard.shopingCardOrder,
  }),
  data() {
    return {
      loading: false,
      dialogWeightVailt: false,
      disheObj: null,
    };
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    editWeight() {
      this.disheObj = this.indexActive;
      this.dialogWeightVailt = true;
    },
    editWeightLoad() {
      this.getShopingCard({ orderNo: this.orderNo });
    },
    colseEditWeight() {
      this.dialogWeightVailt = false;
    },
    async cancelAddDishes() {
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
        };
        await this.$http.post(
          "/cashier-api/api/orderNew/cancelAddDishes",
          data
        );
        this.$router.replace({
          path: "/toPayment",
          query: {
            orderNo: this.orderInfo.parentOrderNo || this.orderInfo.orderNo,
            tableId: this.tableId,
            orderMode: this.orderMode,
          },
        });
      } finally {
        this.loading = false;
      }
    },

    async clearTable() {
      await this.$confirm("此操作将清除桌位状态, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      try {
        this.loading = true;
        let data = {
          tableId: this.tableId,
          orderNo: this.orderNo,
        };
        await this.$http.post("/cashier-api/api/orderNew/clearTable", data);
        this.$router.replace("/selectTable");
      } finally {
        this.loading = false;
      }
    },
    changeTable() {
      this.$router.push({
        path: "/changeTable",
        query: {
          orderNo: this.orderNo,
          tableId: this.tableId,
          path: "selectMenu",
        },
      });
    },
    async addDishes() {
      this.ifReturnFn();
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
          dishesId: this.indexActive.dishesId,
          tasteId: this.indexActive.tasteId,
          priceUnitId: this.indexActive.priceUnitId,
        };
        await this.$http.post("/cashier-api/api/dishes/addDishes", data);
        this.getShopingCard({ orderNo: this.orderNo });
      } finally {
        this.loading = false;
      }
    },
    async reduceDishes() {
      this.ifReturnFn();
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
          dishesId: this.indexActive.dishesId,
          orderDishesId: this.indexActive.orderDishesId,
        };
        await this.$http.post("/cashier-api/api/dishes/reduceDishes", data);
        this.getShopingCard({ orderNo: this.orderNo });
      } finally {
        this.loading = false;
      }
    },
    async removeOneOrderDishes() {
      this.ifReturnFn();
      await this.$confirm("此操作将删除菜品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      });
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
          orderDishesId: this.indexActive.orderDishesId,
        };
        await this.$http.post(
          "/cashier-api/api/dishes/removeOneOrderDishes",
          data
        );
        this.getShopingCard({ orderNo: this.orderNo });
      } finally {
        this.loading = false;
      }
    },
    async giveOrderDishes() {
      this.ifReturnFn();
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
          dishesId: this.indexActive.dishesId,
          orderDishesId: this.indexActive.orderDishesId,
        };
        await this.$http.post("/cashier-api/api/dishes/giveOrderDishes", data);
        this.getShopingCard({ orderNo: this.orderNo });
      } finally {
        this.loading = false;
      }
    },
    async updateCurrentDishesPrice() {
      this.ifReturnFn();
      let inputValue = "";
      if (this.indexActive.price) {
        inputValue = (this.indexActive.price / 100).toFixed(2) || "";
      }
      let { value } = await this.$prompt("请输入价格(元)", "提示", {
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue,
        inputValidator(v) {
          let reg = /^\d{1,5}(\.\d{1,2})?$/;
          if (!reg.test(v)) return "金额只支持0~99999.99";
          if (+v === 0) return "金额只支持0~99999.99";
          return true;
        },
      });
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
          dishesId: this.indexActive.dishesId,
          orderDishesId: this.indexActive.orderDishesId,
          price: (value * 100).toFixed(0),
        };
        await this.$http.post(
          "/cashier-api/api/dishes/updateCurrentDishesPrice",
          data
        );
        this.getShopingCard({ orderNo: this.orderNo });
      } finally {
        this.loading = false;
      }
    },
    async saveOrderRemark() {
      let { value } = await this.$prompt("请输入整单备注", "提示", {
        closeOnClickModal: false,
        inputValue: this.orderInfo.orderRemark,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /^.{0,30}$/,
        inputErrorMessage: "最多支持30个字符",
      });
      try {
        this.loading = true;
        let data = {
          orderNo: this.orderNo,
          orderRemark: value,
        };
        await this.$http.post(
          "/cashier-api/api/orderNew/saveOrderRemark",
          data
        );
        this.getShopingCard({ orderNo: this.orderNo });
        this.$message({
          message: "整单备注保存成功！",
          type: "success",
        });
      } finally {
        this.loading = false;
      }
    },
    ifReturnFn() {
      if (!this.indexActive) {
        this.$message({
          message: "请选择菜品！",
          type: "warning",
        });
        throw new Error("选择菜品！");
      }
    },
  },
};
</script>

<style scoped>
.operation-container {
  height: 100%;
}
.operation-div {
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 0 4px 0 silver;
  font-size: 14px;
  color: #333333;
}
.div1 {
  margin-bottom: 20%;
}
.f15 {
  font-size: 25px;
}
.operation-div > div {
  border-bottom: 1px solid #dedede;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  cursor: pointer;
}
.operation-div > div:hover {
  background-color: rgb(62, 160, 235);
  color: #fff;
}
</style>