<template>
  <div class="view-contion" v-loading="loading">
    <div class="view-search">
      <el-form
        :inline="true"
        :model="formInline"
        class="demo-form-inline"
        size="mini"
        ref="formInline"
      >
        <el-form-item label="会员姓名" prop="memberName">
          <el-input v-model="formInline.memberName"></el-input>
        </el-form-item>
        <el-form-item label="会员手机号" prop="memberPhone">
          <el-input v-model="formInline.memberPhone"></el-input>
        </el-form-item>
        <el-form-item label="会员类型" prop="memberTypeId">
          <el-select v-model="formInline.memberTypeId" placeholder="请选择" clearable>
            <el-option
              v-for="item in typelist"
              :key="item.memberTypeId"
              :label="item.memberTypeName"
              :value="item.memberTypeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="卡号" prop="memberCardNo">
          <el-input v-model="formInline.memberCardNo"></el-input>
        </el-form-item>
        <el-form-item label="订单状态" prop="paymentStatus">
          <el-select v-model="formInline.paymentStatus" placeholder="请选择" clearable>
            <el-option v-for="(v,k) in paymentStatus" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付方式" prop="paymentType">
          <el-select v-model="formInline.paymentType" placeholder="请选择" clearable>
            <el-option v-for="(v,k) in paymentType" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="支付类型" prop="recordMode">
          <el-select v-model="formInline.recordMode" placeholder="请选择" clearable>
            <el-option v-for="(v,k) in recordMode" :key="k" :label="v" :value="k"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="下单时间">
          <el-date-picker
            v-model="formInline.value1"
            type="datetimerange"
            :picker-options="pickerOptions"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker
            v-model="formInline.value2"
            type="datetimerange"
            :picker-options="pickerOptions"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div class="view-button">
        <el-button
          v-if="But.List.power()"
          type="primary"
          @click="searchzin"
          size="mini"
          class="serach"
        >查询</el-button>
        <span class="view-button-rest" @click="reset">重置筛选条件</span>
        <div class="int_view">
          <div></div>
          <div>
            <span class="fonsize">
              <span>
                订单总金额:
                <span>{{statistics.totalOriginAmount?(statistics.totalOriginAmount/100).toFixed(2):"0.00"}}</span>
              </span>
              <span>
                实收总金额:
                <span>{{statistics.totalReallyReceiveAmount?(statistics.totalReallyReceiveAmount/100).toFixed(2):"0.00"}}</span>
              </span>
              <span>
                优惠总金额:
                <span>{{statistics.totalDiscountsAmount?(statistics.totalDiscountsAmount/100).toFixed(2):"0.00"}}</span>
              </span>
            </span>
            <el-button v-if="But.Export.power()" @click="exportall" size="mini" type="primary" class="btn">导出</el-button>
          </div>
        </div>
      </div>
    </div>
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      highlight-current-row
    >
      <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
      <el-table-column prop="mermberName" label="会员姓名"></el-table-column>
      <el-table-column prop="phone" label="会员手机号" width="120"></el-table-column>
      <el-table-column prop="memberTypeName" label="会员类型"></el-table-column>
      <el-table-column prop="memberCardNo" label="卡号" width="150"></el-table-column>
      <el-table-column prop="recordId" label="订单号" width="200"></el-table-column>
      <el-table-column prop="paymentStatus" label="订单状态">
        <template slot-scope="scope">{{paymentStatus[scope.row.paymentStatus]}}</template>
      </el-table-column>
      <el-table-column prop="recordMode" label="支付类型">
        <template slot-scope="scope">{{recordMode[scope.row.recordMode]}}</template>
      </el-table-column>
      <el-table-column prop="paymentType" label="支付方式">
        <template slot-scope="scope">{{paymentType[scope.row.paymentType]}}</template>
      </el-table-column>
      <el-table-column prop="originAmount" label="订单金额(元)" width="150">
        <template slot-scope="scope">{{scope.row.originAmount|money}}</template>
      </el-table-column>
      <el-table-column prop="discountsAmount" label="优惠金额(元)" width="150">
        <template slot-scope="scope">{{scope.row.discountsAmount|money}}</template>
      </el-table-column>
      <el-table-column prop="reallyReceiveAmount" label="实收金额(元)" width="150">
        <template slot-scope="scope">{{scope.row.reallyReceiveAmount|money}}</template>
      </el-table-column>
      <el-table-column prop="balanceChange" label="账户余额变化(元)" width="150">
        <template slot-scope="scope">{{scope.row.balanceChange}}</template>
      </el-table-column>
      <el-table-column prop="accountBalance" label="账户余额(元)" width="150">
        <template slot-scope="scope">{{scope.row.accountBalance|money}}</template>
      </el-table-column>
      <el-table-column prop="recordTime" label="下单时间" width="170"></el-table-column>
      <el-table-column prop="paymentTime" label="支付时间" width="170"></el-table-column>
    </el-table>
    <div class="view-page">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page="page.pageNo"
        :page-size="page.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="page.total"
        prev-text="上一页"
        next-text="下一页"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  paymentStatus,
  paymentType,
  recordMode,
} from "@/common/options.js";
import { notTodayPass } from "@/common/mixins.js";
export default {
  created() {
     let today = new Date().formatDate('yyyy-MM-dd');
    this.formInline.value1 = [today + " 00:00:00", today + " 23:59:59"];
    this.loadlist();
    this.searchzin();
  },
  data() {
    return {
      But: window.UserPower.Membership.MemberOrder,
      formInline: {
        memberName: "",
        memberPhone: "",
        memberTypeId: "",
        memberCardNo: "",
        paymentStatus: "",
        paymentType: "",
        recordMode: "",
        recordTimeStart: "",
        recordTimeEnd: "",
        paymentTimeStart: "",
        paymentTimeEnd: "",
        value1: null,
        value2: null,
      },
      statistics: {},
      paymentStatus: paymentStatus,
      paymentType: paymentType,
      recordMode: recordMode,
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      loading: false,
      typelist: [],
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.choiceDate = minDate.getTime();
          if (maxDate) {
            this.choiceDate = "";
          }
        },
        disabledDate: (time) => {
          if (notTodayPass(time)) {
            return true;
          }
        },
      },
    };
  },
  methods: {
    loadlist() {
      this.loading = true;
      this.$http
        .post("/order-admin/w/memberType/s/all", {})
        .then((res) => {
          this.loading = false;
          this.typelist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    exportall() {
      this.formInline.recordTimeStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.recordTimeEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.paymentTimeStart = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.paymentTimeEnd = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.$http
        .post("/order-admin/w/member/order/export/excel", {
          ...this.formInline,
        })
        .then((res) => {
          window.open(res.data);
        });
    },
    indexMethod(index) {
      return (this.page.pageNo - 1) * this.page.pageSize + index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.search();
    },
    searchzin() {
      this.page.pageNo = 1;
      this.search();
    },
    search() {
      this.formInline.recordTimeStart = this.formInline.value1
        ? this.formInline.value1[0]
        : "";
      this.formInline.recordTimeEnd = this.formInline.value1
        ? this.formInline.value1[1]
        : "";
      this.formInline.paymentTimeStart = this.formInline.value2
        ? this.formInline.value2[0]
        : "";
      this.formInline.paymentTimeEnd = this.formInline.value2
        ? this.formInline.value2[1]
        : "";
      this.loading = true;
      this.$http
        .post("/order-admin/w/member/order/s", {
          ...this.formInline,
          ...this.page,
        })
        .then((res) => {
      
          this.loading = false;
          this.tableData = res.page.records;
          this.statistics = res.countRespVo;
          this.page.total = res.page.total;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    reset() {
      this.$refs["formInline"].resetFields();
      this.formInline.value1 = [];
      this.formInline.value2 = [];
    },
    exportzin() {
      this.Visibleupdatezin = true;
    },
  },
};
</script>

<style scoped>
.fonsize span {
  margin-left: 10px;
}
.fonsize span span {
  color: #409eff;
  margin-left: 0px;
}
.btn {
  margin-left: 20px;
}
</style>


