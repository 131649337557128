window.UserPower = {
    home: {
        List: 147,
        searchMechanism: 16, //打印机设置
        Staffmanagement: 6, //员工管理
        information: 36, //基本信息设置
        equipmentAdminBoard: 47, //桌位信息
        sinformation: 78, //  菜品信息
        transactionAdminSear: 70,//销售统计
        Membership: 69,//会员信息
        shanghuSearchInfo: 72//订单管理 

    },
    RestRoomSet: { // 餐厅设置
        Restaurant: { //餐厅信息
            basicInfo: {
                power: 36,
                Set: 174 //基本信息 保存按钮
            },
            basicSet: {
                power: 173,
                Set: 175 //基本设置 保存按钮
            }
        },
        searchMechanism: { //设备管理
            Dayinji: {
                power: 16,
                List: 17, //查询
                Edit: 21, //设置
                Delete: 19, //删除
                SwitchAble: 18 //停用启用
            },
            Bobaoqi: {
                power: 209,
                List: 210, //查询
                Edit: 211, //设置
                Delete: 213, //删除
                SwitchAble: 212 //停用启用
            }
        },
        Staffmanagement: { //员工管理
            management: { //员工管理
                power: 6,
                List: 24,
                Add: 23,
                Edit: 25,
                SwitchAble: 27
            },
            privilege: { //权限管理
                power: 7,
                List: 29,
                Add: 28,
                Edit: 30,
                Detail: 203
            }
        }
    },
    Restauranttable: { //餐厅桌位
        equipmentAdminCode: { //餐厅位置
            List: 48,
            Add: 49,
            Edit: 51,
            Delete: 53
        },
        equipmentAdminBoard: { //桌位信息
            List: 54,
            Add: 55,
            Edit: 56,
            Delete: 58,
            Down: 59,
            AllDown: 60
        }
    },
    variety: { //菜品管理  
        classification: { //菜品分类
            viewInfo: { //菜品分类
                power: 176,
                List: 80,
                Add: 81,
                Edit: 82,
                SwitchAble: 145,
                Export: 83,
                Sort: 245,
            },
            viewSet: { //计价单位
                power: 87,
                List: 86,
                Add: 84,
                Delete: 85
            },
            viewEat: { //做法口味
                power: 178,
                List: 179,
                Add: 181,
                Delete: 180
            }
        },
        sinformation: { //菜品信息
            List: 96,
            Add: 93,
            Edit: 100,
            Import: 99,
            importImgs: 265,
            Export: 95,
            UpDown: 142,
            AllUp: 94,
            AllDown: 192,
            Sort: 247,
            Delete: 246
        },
        Recommended: { //推荐菜品
            List: 101,
            Add: 102
        }
    },
    order: { //订单管理 
        shanghuSearchInfo: { //订单查询
            List: 73,
            Export: 74
        }
    },
    Salesstatistics: { //数据统计
        transactionAdminSear: { //销售统计
            List: 67
        },
        transactionAdminQuit: { //流水统计
            List: 68
        }
    },
    Preferential: { //优惠促销
        promotion: { // 优惠活动
            List: 37,
            AddDiscount: 38, //添加打折
            AddFull: 39, //添加满减
            Edit: 89,
            Stop: 143
        },
        shareProfitAdminHis: { //特价活动
            List: 43,
            Add: 44,
            Cancel: 146, //取消
        }
    },
    Membership: { //会员管理
        Membership: {
            List: 61,
            Add: 63,
            Edit: 64,
            Editmoney: 239,
            Import: 65,
            Detail: 197,
            Logout: 215,
            Export: 244,
        },
        MemberOrder: {// 会员订单
            List: 219,
            Export: 220,
        },
        MemberType: {//会员类型
            List: 221,
            Add: 222,
            Edit: 224,
            Delete: 225
        },
        MemberActivity: {//充值优惠
            List: 226,
            Add: 227,
            Detail: 229,
            Delete: 228
        }
    },
    serve: { //服务台
        servicemember: { //会员服务台
            member: {
                power: 240,
                List: 106,
                Add: 105,
                Edit: 108,
                Import: 109,
                Detail: 196,
                Logout: 231,
                Editmoney: 243,
                Recharge: 232,
            },
            memberorder: {
                power: 241,
                List: 242
            }
        },
        orderdetail: { //订单服务台
            List: 112,
            Payment: 183
        },
        statusopen: { //点餐
            List: 114,
            QuitCai: 132,
            DownDan: 117
        }
    }
}