<template>
  <div class="view_contant">
    <div class="contant_left">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="mini">
        <div class="contain">
          <el-row>
            <div class="hr_title" style="margin-bottom: 25px">
              <span>订单信息</span>
            </div>
            <el-col :span="12">
              <el-form-item label="订单编号:" prop="orderNo">
                <span>{{ ruleForm.orderNo }}</span>
              </el-form-item>
              <el-form-item label="下单时间:" prop="orderTime">
                <span>{{ ruleForm.orderTime }}</span>
              </el-form-item>
              <el-form-item label="会员姓名:" prop="memberName">
                <span>{{ ruleForm.memberName }}</span>
              </el-form-item>
              <el-form-item label="支付方式:" prop="payType">
                <span>{{ ruleForm.payType }}</span>
              </el-form-item>
              <el-form-item label="交易单号:" prop="tradeNo">
                <span>{{ ruleForm.tradeNo }}</span>
              </el-form-item>
              <el-form-item label="平台优惠:" prop="couponName" v-if="ruleForm.couponName">
                <span>{{ ruleForm.couponName }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付状态:" prop="orderStatus">
                <span>{{ ruleForm.orderStatus }}</span>
              </el-form-item>
              <el-form-item label="收银员:" prop="cashier">
                <span>{{ ruleForm.cashier }}</span>
              </el-form-item>
              <el-form-item label="会员手机号:" prop="memberPhone">
                <span>{{ ruleForm.memberPhone }}</span>
              </el-form-item>
              <el-form-item label="优惠信息:" prop="discountInfo">
                <span>{{ ruleForm.discountInfo }}</span>
              </el-form-item>
              <el-form-item label="支付时间:" prop="payTime">
                <span>{{ ruleForm.payTime }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <div class="hr_title" style="margin-bottom: 20px">交易信息</div>
            <el-col :span="12">
              <el-form-item label="原价:" prop="originPrice">
                <span>{{ ruleForm.originPrice | money }}元</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="会员价:" prop="memberPrice">
                <span>{{ ruleForm.memberPrice | money }}元</span>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="ruleForm.orderMode != 2">
              <el-form-item label="其他:" prop="totalOtherFee">
                <div class="autin">
                  {{ ruleForm.totalOtherFee | money }}元
                  <span>(打包:{{ ruleForm.packingFee | money }}元</span>
                  <span>餐具:{{ ruleForm.tablewareFee | money }}元</span>
                  <span>桌位:{{ ruleForm.tableSeatingFee | money }}元)</span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <div class="hr_title" style="margin-bottom: 20px"></div>
            <el-col :span="12">
              <el-form-item label="订单金额:" prop="orderAmount">
                <span class="fontsize">{{ ruleForm.orderAmount | money }}元</span>
              </el-form-item>
              <el-form-item label="应收:" prop="shouldAmount">
                <span class="fontsize">{{ ruleForm.shouldAmount | money }}元</span>
              </el-form-item>
              <el-form-item label="抹零金额:" v-if="ruleForm.notCountAmount != 0">
                <span class="fontsize">{{ ruleForm.notCountAmount | money }}元</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="优惠:" prop="discountsAmount">
                <span class="fontsize">{{ ruleForm.discountsAmount | money }}元</span>
              </el-form-item>
              <el-form-item label="实收:" prop="reallyAmount">
                <span class="fontsize">{{ ruleForm.reallyAmount | money }}元</span>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div class="phone_inner">
      <div class="header_phone">
        <div v-if="ruleForm.orderMode != 2">
          <span>{{ ruleForm.tableName }}号</span>
          <span>{{ ruleForm.tableNo }}座</span>
        </div>
        <div v-else>
          <span>取餐码：{{ ruleForm.tableName | splitD }}</span>
        </div>
        <div>
          <span>
            <span>下单员:</span>
            {{ ruleForm.cashier }}
          </span>
          <span>{{ ruleForm.orderTime }}</span>
        </div>
      </div>
      <div class="detailtab">
        <el-table ref="multipleTable" :data="orderDishers" tooltip-effect="dark" style="width: 100%" size="mini" max-height="360" default-expand-all row-key="disherId" highlight-current-row
          :tree-props="{
            children: 'childOrderDisher',
            hasChildren: 'hasChildren',
          }">
          <el-table-column prop="dishersName" label="菜品名称">
            <template slot-scope="scope">
              <p>{{ scope.row.dishersName }}</p>
              <p class="huiyuan">
                <el-popover trigger="hover" placement="top" v-if="scope.row.remark">
                  <p>{{ scope.row.remark }}</p>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">备注详情</el-tag>
                  </div>
                </el-popover>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="selectCount" label="数量/重量">
            <template slot-scope="scope">
                      {{ scope.row.dishesPriceType==1?scope.row.disherUnitValue:scope.row.selectCount}}

                    </template>
          </el-table-column>
          <el-table-column prop="unitName" label="单位"></el-table-column>
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">
              <p>¥{{ scope.row.price | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberPrice != '0'">
                ¥{{ scope.row.memberPrice | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" label="总价">
            <template slot-scope="scope">
              <p>¥{{ scope.row.totalPrice | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.totalMemPrice != '0'">
                ¥{{ scope.row.totalMemPrice | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="operatorUser" label="操作人"></el-table-column>
        </el-table>
        <div class="rack">备注:{{ ruleForm.orderRemark }}</div>
      </div>
      <div class="detailtab" v-if="ruleForm.orderMode != 2">
        <div class="title_inner">退菜信息</div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" size="mini" highlight-current-row max-height="360" default-expand-all row-key="disherId" :tree-props="{
            children: 'childOrderDisher',
            hasChildren: 'hasChildren',
          }">
          <el-table-column prop="dishersName" label="菜品名称">
            <template slot-scope="scope">
              <p>{{ scope.row.dishersName }}</p>
              <p class="huiyuan">
                <el-popover trigger="hover" placement="top" v-if="scope.row.remark">
                  <p>{{ scope.row.remark }}</p>
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">备注详情</el-tag>
                  </div>
                </el-popover>
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="selectCount" label="数量/重量">
            <template slot-scope="scope">
              {{ scope.row.dishesPriceType==1?scope.row.disherUnitValue:scope.row.selectCount}}

            </template>
          </el-table-column>
          <el-table-column prop="unitName" label="单位"></el-table-column>
          <el-table-column prop="price" label="单价">
            <template slot-scope="scope">
              <p>¥{{ scope.row.price | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.memberPrice != '0'">
                ¥{{ scope.row.memberPrice | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="totalPrice" label="总价">
            <template slot-scope="scope">
              <p>¥{{ scope.row.totalPrice | money }}</p>
              <p class="huiyuan" v-if="openCommonMermber != 0 && scope.row.totalMemPrice != '0'">
                ¥{{ scope.row.totalMemPrice | money }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="operatorUser" label="操作人"></el-table-column>
        </el-table>
        <!-- <div class="rack">备注:{{ ruleForm.backOrderRemark }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderId"],
  created() {
    this.detail(this.orderId);
    this.merload();
  },
  data() {
    return {
      But: window.UserPower.serve.orderdetail,
      flag3: false,
      paymentMode: "",
      statusobj: {
        1: "正常 ",
        2: "退菜",
      },
      memberTypeobj: {
        1: "普通会员",
      },
      tableData: [],
      orderDishers: [],
      ruleForm: {},
      paymentTypeobj: {
        1: "现金",
        2: "扫码",
        3: "刷卡",
        4: "其他",
        5: "免单",
        6: "账户支付",
      },
      rules: {},
      printKinds: [],
      printTypes: [],
      printKindsi: "",
      openCommonMermber: "",
    };
  },
  methods: {
    merload() {
      this.$http.get(`/order-admin/w/base/merchantInfo`).then((res) => {
        this.paymentMode = res.merchantInfoVo.paymentMode;
        this.openCommonMermber = res.merchantInfoVo.openCommonMermber;
        if (this.paymentMode != "1") {
          this.flag3 = true;
        }
      });
    },
    handleCommand(command) {
      if (command == "a") {
        this.printKindsi = "0";
      } else if (command == "b") {
        this.printKindsi = "1";
      } else {
        this.printKindsi = "2";
      }
      this.printTypes.push("2", "3");
      this.printKinds.push(this.printKindsi);
      this.printchencken();
    },
    print(val) {
      this.printKindsi = val;
      if (val == 4) {
        this.printTypes = [];
      } else {
        this.printTypes.push(1);
      }
      this.printKinds.push(val);
      this.printchencken();
    },
    printchencken() {
      let data = {
        orderId: this.$route.query.orderId,
        printTypes: this.printTypes,
        printKinds: this.printKinds,
        printerType: 2,
      };
      if (this.printKindsi == "0") {
        data.printKinds = this.printKinds;
        data.printstatus = "1";
      } else if (this.printKindsi == "3") {
        delete data.printTypes;
      }
      if (this.paymentMode == "1") {
        this.printTypes = [];
      }
      if (this.ruleForm.orderMode) {
        data.orderMode = this.ruleForm.orderMode;
      }
      this.$http
        .post(`/order-admin/mqtt/print`, data)
        .then((res) => {
          this.$message({
            message: "打印成功",
            offset: 160,
            type: "success",
          });
          this.printKinds = [];
          this.printTypes = [];
        })
        .catch((_) => {
          this.printKinds = [];
          this.printTypes = [];
        });
    },
    closeorder() {
      this.$router.push({
        path: "/selectTable",
        meta: {
          title: "点餐",
        },
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    handleCurrentChange(val) {
      this.page.pageNo = val;
      this.detail();
    },
    detail(orderId) {
      this.$http
        .post(`/cashier-api/f/order/detail`, { orderId })
        .then((res) => {
          this.ruleForm = res;
          this.tableData = res.backDishers;
          this.orderDishers = res.orderDishers;
        });
    },
    orderpay() {
      // this.$router.push({
      //   path: "/serve",
      //   query: {
      //     orderNo: this.ruleForm.orderNo,
      //     ordertype: 1
      //   }
      // });
      // localStorage.setItem("payflag", false);
    },
  },
};
</script>

<style scoped>
.view_contant {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.contain {
  height: calc(100% - 60px);
  overflow: auto;
}
.contant_left {
  width: 50%;
  border: 1px solid #dedede;
  position: relative;
}
.footer_zin {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
}
.input-width {
  width: 238px;
}
.demo-ruleForm {
  padding: 0px 20px 0 20px;
  margin-top: 20px;
}
.dayinOrder {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinReceipt {
  width: 100px;
  height: 40px;
  border: 1px solid #108af9;
  border-radius: 2px;
  color: #108af9;
  text-align: center;
  line-height: 40px;
  display: inline-block;
  margin-left: 20px;
  font-size: 14px;
  cursor: pointer;
}
.dayinReceipt:nth-of-type(2) {
  margin-left: 10px;
}
.footer_btn button {
  width: 160px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #108af9;
  text-align: center;
  font-size: 14px;
  border: none;
  border-radius: 0;
}
.footer_btn button:nth-of-type(2) {
  background: #108af9;
  color: #ffffff;
  margin-left: 0;
}
.phone_inner {
  width: 45%;
  border: 1px solid #dedede;
  margin: 0 2%;
  height: 100%;
  overflow: auto;
}
.header_phone {
  height: 111px;
  border-bottom: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.header_phone div {
  display: flex;
  justify-content: space-between;
  margin: 0 26px;
  font-size: 22px;
}
.header_phone div:nth-of-type(2) {
  font-size: 14px;
  margin-top: 14px;
}
.rack {
  height: 43px;
  border-bottom: 1px solid #dedede;
  line-height: 35px;
  font-size: 14px;
  padding-left: 27px;
}
.title_inner {
  font-size: 22px;
  color: #333333;
  margin: 20px 0 0 26px;
}
.huiyuan {
  color: #ff6969;
}
.autin > span {
  margin-left: 15px;
}
.fontsize {
  font-size: 20px;
  color: #ff6969;
}
</style>
