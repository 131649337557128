<template>
  <div v-loading="loading">
    <el-form :model="formzinernil" class="demo-form-inline" size="mini" ref="formzinernil" label-width="100px">
      <el-form-item label="菜品名称:" prop="itemName">{{indexActive.dishesName}}</el-form-item>
      <el-form-item label="退菜数量:" prop="refuseCount" v-if="indexActive.priceType!=5">
        <el-input-number v-model="formzinernil.refuseCount" :min="1" :max="indexActive.selectCount"></el-input-number>
      </el-form-item>
      <el-form-item label="退菜重量:" prop="refuseCount" v-else>
        {{ indexActive.disherUnitValue }}    {{  indexActive.priceUnitName}}
      </el-form-item>
      <el-form-item label="退菜原因:" prop="disherUnitValue">
        <el-input type="textarea" maxlength="30" v-model="formzinernil.disherUnitValue" placeholder="请输入退菜原因"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitedzin" size="mini">确认退菜</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: mapState({
    indexActive: (state) =>
      state.shopingCard.shopingCardActive || { dishesName: "", selectCount: 1 },
  }),
  props: { orderNo: String },
  created() {
    this.formzinernil.refuseCount = 1;
  },
  data() {
    return {
      formzinernil: {
        refuseCount: "",
        refuseReason: "",
      },
      options: [],
      loading: false,
    };
  },
  methods: {
    loaddetail() {
      this.options = [];
      for (var i = 0; i < this.val.selectCount; i++) {
        this.options.push(i + 1);
      }
    },
    close() {
      this.$emit("close");
      this.$refs["formzinernil"].resetFields();
    },
    submitedzin() {
      this.$refs["formzinernil"].validate(async (valid) => {
        if (!valid) return;
        try {
          this.loading = true;
          await this.$http.post("/cashier-api/api/dishes/refuseDishesOrder", {
            orderNo: this.orderNo,
            dishesId: this.indexActive.dishesId,
            orderDishesId: this.indexActive.orderDishesId,
            reduceCount: this.formzinernil.refuseCount || 0,
            refuseReason: this.formzinernil.refuseReason,
          });
          this.$emit("close");
        } finally {
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
</style>