<template>
  <div v-loading="loading">
    <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" size="mini">
      <el-row>
        <div class="hr_title">满减活动</div>
        <el-col :span="9" style="margin-top:10px">
          <el-form-item label="活动名称" prop="activityName">
            <span>{{ruleForm.activityName}}</span>
          </el-form-item>
          <el-form-item label="满减信息" prop="startAmount" v-if="flag">
            <div v-for="(item,key) in rebate" :key="key">
              <span>满{{item.startAmount|money}}减{{item.reduceAmount|money}}</span>
            </div>
          </el-form-item>
          <el-form-item label="全场折扣" v-if="!flag">
            <span>{{ruleForm.discount|money}}</span>
            <div class="input-tishi">实例:88折，填写0.88</div>
          </el-form-item>
        </el-col>
        <el-col :span="15" style="margin-top:10px">
          <el-form-item label="活动时间" prop="activityBeginTm">
            <span>
              {{ruleForm.activityBeginTm}}
              ~{{ruleForm.activityEndTm}}
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <div class="hr_title">活动商品</div>
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          size="mini"
        >
          <el-table-column label="序号" type="index" :index="indexMethod"></el-table-column>
          <el-table-column prop="dishersName" label="菜品名称"></el-table-column>
          <el-table-column prop="categoryName" label="菜品分类"></el-table-column>
          <el-table-column label="计价方式">
            <template
              slot-scope="scope"
            >{{options1[scope.row.disherUnitPriceSearchVoList[0].priceType]}}</template>
          </el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].price" label="单价">
            <template slot-scope="scope">{{scope.row.disherUnitPriceSearchVoList[0].price|money}}</template>
          </el-table-column>
          <el-table-column prop="disherUnitPriceSearchVoList[0].memberPrice" label="会员价">
            <template
              slot-scope="scope"
            >{{scope.row.disherUnitPriceSearchVoList[0].memberPrice|money}}</template>
          </el-table-column>
        </el-table>
      </el-row>
    </el-form>
    <div class="footer">
      <el-button type="primary" @click="closew" size="small">关闭</el-button>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    this.serach();
  },
  data() {
    return {
      tableData: [
        {
          disherUnitPriceSearchVoList: [
            {
              priceType: "",
              price: "",
              memberPrice: ""
            }
          ]
        }
      ],
      options1: {
        0: "常规计价",
        1: "称重计价",
        2: "份量计价",
        3: "时价"
      },
      rebate: [],
      ruleForm: {
        activityName: "",
        activityBeginTm: "",
        activityEndTm: ""
      },
      flag: true,
      loading: false
    };
  },
  methods: {
    indexMethod(index) {
      return index + 1;
    },
    serach() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/activity/detail`, {
          activityId: this.$route.query.id
        })
        .then(res => {
          this.loading = false;
          let data = res.data;
          if (this.$route.query.type == 1) {
            this.rebate = data.rebateList;
            this.flag = true;
          } else {
            this.flag = false;
          }
          this.ruleForm.activityName = data.activityName;
          this.ruleForm.activityBeginTm = data.activityBeginTm;
          this.ruleForm.activityEndTm = data.activityEndTm;
          this.tableData = data.dishersInfos;
          this.ruleForm.discount = data.discount;
        })
        .catch(error => {
          this.loading = false;
        });
    },
    closew() {
      this.$router.push("/Preferential");
    }
  }
};
</script>

<style scoped>
.input-width {
  width: 238px;
}
.demo-ruleForm {
  padding: 20px 20px 0 20px;
}
.dayinOrder {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinReceipt {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 100px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.dayinToKitchen {
  border: 1px solid #dcdee0;
  border-radius: 2px;
  color: #108af9;
  width: 80px;
  text-align: center;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  margin-left: 15px;
}
.footer {
  width: 100%;
  position: fixed;
  height: 60px;
  bottom: 2px;
  background: #ffffff;
  box-shadow: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
