<template>
  <div class="img-box" :title="errorText">
    <div class="content" :class='{error:status==3?"error":"",success:status==2?"success":""}'>
      <img :src="imgUrl" alt="">
      <div class="shade">
        <el-progress :status="statusLogo" type="circle" :percentage="percentage" :width="60" text-color="#ffffff"></el-progress>
        <div class="status" :class="{err:status==3,suc:status==2}">{{statusMapText[status]}}</div>
      </div>
    </div>
    <div class="name" :title="upfile.name">
      {{upfile.name}}
    </div>
  </div>
</template>

<script>
export default {
  props: ["upfile"],
  computed: {
    imgUrl() {
      return window.URL.createObjectURL(this.upfile);
    },
  },
  data() {
    return {
      merchantId: localStorage.getItem("merchantId"),
      percentage: 0,
      status: 0,
      statusLogo: "",
      errorText: "",
      statusMapText: {
        0: "待上传",
        1: "上传中",
        2: "上传成功",
        3: "上传失败",
      },
      Time: null,
    };
  },
  methods: {
    onError(text) {
      this.statusLogo = "exception";
      this.status = 3;
      this.percentage = 100;
      clearInterval(this.Time);
      this.errorText = text;
      this.$emit("onError", { text, name: this.upfile.name });
    },
    onSucess() {
      this.statusLogo = "success";
      this.status = 2;
      this.percentage = 100;
      clearInterval(this.Time);
      this.$emit("onSuccess");
    },
    async onUpFileFn() {
      let fileSize = this.upfile.size / 1024 / 1024;
      if (fileSize > 2) return this.onError("文件大于2M");
      this.status = 1;
      let formdata = new FormData();
      formdata.append("merchantId", this.merchantId);
      formdata.append("file", this.upfile);
      this.Time = setInterval(() => {
        let nextNum = this.percentage + Math.floor(Math.random() * 10);
        if (nextNum > 100) {
          clearInterval(this.Time);
        } else {
          this.percentage = nextNum;
        }
      }, 500);
      try {
        let res = await this.$http.post(
          "/order-admin/w/file/importPictures",
          formdata
        );
        if (res.data.successFlag) {
          return this.onSucess();
        } else {
          return this.onError(res.data.failMsg);
        }
      } catch (err) {
        return this.onError("上传失败");
      }
    },
  },
};
</script>

<style scoped>
.name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  margin-top: 5px;
}
.img-box {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 130px;
  cursor: pointer;
}
.content {
  width: 130px;
  height: 130px;
  background-color: aliceblue;
  border-radius: 3px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 8px 1px #909399;
  transition: all 0.2s;
}
.success {
  box-shadow: 0 0 1px 1px #67c23a;
}
.error {
  box-shadow: 0 0 1px 1px #f56c6c;
}
.content img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.shade {
  width: 100%;
  height: 100%;
  background-color: rgba(242, 237, 237, 0.6);
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #000;
}
.status {
  margin-top: 6px;
  font-weight: bold;
}
.status.err {
  color: #ff4949;
}
.status.suc {
  color: #13ce66;
}
</style>