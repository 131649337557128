<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzine"
      class="demo-form-inline"
      size="mini"
      ref="formzine"
      :rules="rules"
    >
      <el-form-item label="姓名:" prop="mermberName">
        <el-input v-model.trim="formzine.mermberName" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="手机号:" prop="phone">
        <el-input v-model.trim="formzine.phone"></el-input>
      </el-form-item>
      <el-form-item label="卡号:" prop="memberCardNo">
        <el-input v-model.trim="formzine.memberCardNo"></el-input>
      </el-form-item>
      <el-form-item label="是否实名认证:" prop="realNameFlag">
        <el-switch
          v-model="formzine.realNameFlag"
          active-color="#409EFF"
          inactive-color="#dcdfe6"
        ></el-switch>
      </el-form-item>
      <el-form-item label="会员类型:" prop="memberType">
        <el-select v-model="formzine.memberType" placeholder="请选择" @change="$forceUpdate()">
          <el-option
            v-for="item in typelist"
            :key="item.memberTypeId"
            :label="item.memberTypeName"
            :value="item.memberTypeId"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          type="textarea"
          v-model.trim="formzine.remark"
          style="width: 200px"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="submite" type="primary" size="small">确定</el-button>
      <el-button @click="close" size="small">取消</el-button>
    </span>
  </div>
</template>

<script>
export default {
  created() {
    if (this.title == "编辑会员") {
      this.detail();
    }
    this.loadlist();
  },
  props: { title: String, memberId: String },
  data() {
    var reg = (rule, value, callback) => {
      let rez = /^1\d{10}$/;
      if (!rez.test(value)) {
        return callback(new Error("请输入正确的手机号"));
      }
      callback();
    };
    return {
      typelist: [],
      formzine: {
        remark: "",
        memberType: "",
        realNameFlag: false,
        phone: "",
        mermberName: "",
      },
      loading: false,
      rules: {
        mermberName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
          { min: 1, max: 20, message: "支持1—20个字内", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "手机号必填", trigger: "blur" },
          { validator: reg, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    loadlist() {
      this.loading = true;
      this.$http
        .post(`/order-admin/w/memberType/s/all`, {
          pageFlag: false,
        })
        .then((res) => {
         
          this.loading = false;
          this.typelist = res.data;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    edit() {
      this.dis = false;
    },
    detail() {
      this.loading = true;
      this.$http
        .post(`/cashier-api/f/member/detail`, {
          memberId: this.memberId,
        })
        .then((res) => {
          this.loading = false;
          this.formzine = res;
          this.formzine.memberType = res.memberTypeId;
          this.formzine.realNameFlag =
            this.formzine.realNameFlag == "1" ? true : false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    submite() {
      this.$refs["formzine"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let url =
            this.title == "添加会员"
              ? "/cashier-api/f/member/create"
              : "/cashier-api/f/member/modify";
          this.formzine.realNameFlag =
            this.formzine.realNameFlag == true ? "1" : "0";
          this.$http
            .post(`${url}`, {
              ...this.formzine,
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                message: `${this.title}成功`,
                offset: 160,
                type: "success",
              });
              this.$emit("close");
              this.$emit("search");
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.btn {
  color: #409eff;
}
</style>