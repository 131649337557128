<template>
  <div class="h100">
    <el-tabs v-model="activeName" @tab-click="handleClick" class="h100" type="border-card">
      <el-tab-pane label="会员信息" name="1" class="h100" v-if="But.member.power.power()">
        <view-info class="h100" :namezin="namezin" ref="viewa"></view-info>
      </el-tab-pane>
      <el-tab-pane label="会员订单" name="2" v-if="But.memberorder.power.power()">
        <view-inforder class="h100" :namezin="namezin" ref="viewb"></view-inforder>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import viewInfo from "./member/index.vue";
import viewInforder from "./memberorder/index.vue";
export default {
  created() {
   
  },
  components: {
    viewInfo,
    viewInforder,
  },
  data() {
    return {
      But: window.UserPower.serve.servicemember,
      activeName: "1",
      namezin: "1",
    };
  },
  methods: {
    handleClick(val) {
      this.namezin = val.name;
    },
  },
};
</script>

<style scoped>
.iconfont {
  color: #333333;
}
</style>