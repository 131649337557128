<template>
  <div class="guige" v-loading="loading">
    <el-form :inline="true" :model="formzinernil" class="demo-form-inline" ref="formzinernil" :rules="rules">
      <div v-if="dishersObj.tasteList.length != 0">
        <div class="fontal">选择口味</div>
        <div class="radio">
          <div v-for="(item, index) in dishersObj.tasteList" :class="index == isActiveTaste ? 'active' : ''" @click="checkedTasteItem(index, item)" :key="index">
            {{ item.tasteName }}
          </div>
        </div>
      </div>
      <div v-if="dishersObj.departList.length!=0" style="margin-top:30px;">
        <div class="fontal">选择规格</div>
        <div class="radio">
          <div v-for="(item, index) in dishersObj.departList" :class="index == isActive ? 'active' : ''" @click="checkedItem(index, item)" :key="index">
            {{ item.priceUnitName }}
          </div>
        </div>
      </div>
      <div v-if="dishersObj.priceType==1">
        <div class="fontal">菜品重量</div>
        <el-form-item prop="disherUnitValue">
          <el-input style="width:150px" @change="disherUnitValueChange" v-model="formzinernil.disherUnitValue" placeholder="请输入菜品重量，0.1~99.9">
            <template slot="append">{{dishersObj.priceUnitName}}</template>
          </el-input>
        </el-form-item>
      </div>
      <div v-else>
        <div class="fontal">菜品数量</div>
        <el-input-number v-model="formzinernil.addCount" :disabled="dishersObj.hasSpecialPrice" @change="addCountChang" :min="minNum" :max="99"></el-input-number>
      </div>

      <!-- <div class="fontal_a">选择配菜</div>
      <div class="contain_inner">
        <div
          class="contain_inner_a"
          v-for="(item,index) in formzinernil.garnishInfoVos"
          :key="index"
        >
          <div>{{item.garnishName}}</div>
          <div>
            <div>¥{{item.price/100}}</div>
            <div class="add_reduce">
              <img
                src="@/assets/image/icon-jian.png"
                @click="loadfuntion('减',item)"
                v-if="item.garnishCount>0"
              />
              <span>{{item.garnishCount}}</span>
              <img src="@/assets/image/icon-jia.png" @click="loadfuntion('加',item)" />
            </div>
          </div>
        </div>
      </div>-->
    </el-form>
    <div class="footer_a">
      <template>
        <span class="cFF6969" v-if="dishersObj.hasSpecialPrice">￥{{local.specialPrice|money}}<span class="price-txt">特价</span></span>
        <span class="cFF6969" v-if="!dishersObj.hasSpecialPrice&&dishersObj.openCommonMermber==1">￥{{local.memberPrice|money}}<span class="price-txt">会员价</span></span>
        <span style="margin-right:20px" v-if="local.price"> ￥{{local.price|money}}</span>
      </template>
      <el-button type="primary" @click="submitedzin" size="mini">确定</el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["dishersObj", "orderNo"],
  created() {
    this.changeDIshers(this.dishersObj);
    console.log(this.dishersObj);
  },
  computed: {
    ...mapState({
      shopingCardIds: (state) => state.shopingCard.shopingCardIds,
    }),
  },
  data() {
    return {
      minNum: 1,
      local: {
        specialPrice: "",
        memberPrice: "",
        price: "",
      },
      formzinernil: {
        orderNo: "",
        dishesId: "",
        addCount: "1",
        tasteId: "",
        priceUnitId: "",
        disherUnitValue: 1,
      },
      departsarr: [],
      tastearr: [],
      rules: {
        disherUnitValue: [
          { required: true, message: "必填项", trigger: "blur" },
          {
            validator: (r, v, cb) => {
              if (v === "") return cb();
              if (!/^\d{1,2}(\.\d)?$/.test(v)) {
                return cb(new Error("范围；0.1~99.9"));
              }
              if (+v == 0) {
                return cb(new Error("范围；0.1~99.9"));
              }
              return cb();
            },
            trigger: "blur",
          },
        ],
      },
      isActive: 0,
      isActiveTaste: 0,
      priceType: "",
      loading: false,
      baseLocal: null,
    };
  },
  methods: {
    ...mapActions(["getShopingCard"]),
    changeDIshers(v) {
      this.formzinernil.orderNo = this.orderNo;
      this.formzinernil.dishesId = v.dishesId;
      this.formzinernil.tasteId = v.tasteList[0]?.tasteId;
      this.formzinernil.priceUnitId = "";
      if (v.departList.length != 0) {
        this.formzinernil.priceUnitId = v.departList[0]?.priceUnitId;
        this.baseLocal = v.departList[0];
      } else {
        this.baseLocal = v;
      }
      if (v.minimumQuantity > 1 && !this.shopingCardIds.includes(v.dishesId)) {
        this.minNum = this.formzinernil.addCount = v.minimumQuantity;
      }
      if (v.priceType == 1) {
        this.local = this.calculatePrice(1);
      } else {
        this.local = this.calculatePrice(this.formzinernil.addCount);
      }
    },
    addCountChang(value) {
      this.local = this.calculatePrice(value);
    },
    disherUnitValueChange(value) {
      this.local = this.calculatePrice(value);
    },
    calculatePrice(num) {
      let v = this.baseLocal;
      return {
        specialPrice: (v.specialPrice * num).toFixed(0),
        memberPrice: (v.memberPrice * num).toFixed(0),
        price: (v.price * num).toFixed(0),
      };
    },
    checkedItem(index, item) {
      this.isActive = index;
      this.baseLocal = item;
      this.local = this.calculatePrice(this.formzinernil.addCount);
      this.formzinernil.priceUnitId = item.priceUnitId;
    },
    checkedTasteItem(index, item) {
      this.isActiveTaste = index;
      this.formzinernil.tasteId = item.tasteId;
    },
    close() {
      this.$emit("close");
    },
    submitedzin() {
      this.$refs.formzinernil.validate((valid) => {
        if (!valid) return;
        this.loading = true;
        this.$http
          .post("/cashier-api/api/dishes/addDishes", {
            ...this.formzinernil,
          })
          .then(() => {
            this.loading = false;
            this.$message({
              message: "添加成功",
              type: "success",
              offset: 160,
            });
            this.$emit("close");
            this.getShopingCard({ orderNo: this.formzinernil.orderNo });
          })
          .catch(() => {
            this.loading = false;
          });
      });
    },
    loadfuntion(a, item) {
      if (a == "加") {
        item.garnishCount++;
      } else {
        item.garnishCount--;
      }
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.fontal {
  font-size: 14px;
  margin: 10px 0 10px 0;
}
.radio {
  display: flex;
  justify-content: flex-start;
}
.radio div {
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
  text-align: center;
  line-height: 50px;
  margin-right: 20px;
}
.active {
  background: #ecf6ff !important;
  border: 1px solid #108af9 !important;
  border-radius: 4px !important;
  color: #108af9 !important;
}
.fontal_a {
  margin: 30px 0 10px 0;
}
.contain_inner {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.contain_inner_a {
  width: 150px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 10px;
  margin: 0 20px 20px 0;
}
.contain_inner_a div:nth-of-type(2) {
  display: flex;
  justify-content: space-between;
}
.contain_inner_a div:nth-of-type(2) img {
  width: 12px;
  height: 12px;
}
.add_reduce {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.add_reduce img,
span {
  margin-right: 5px;
}
.footer_a {
  width: 100%;
  text-align: right;
  margin: 50px 0 20px;
}
.price-txt {
  background: #3c2c1f;
  font-size: 12px;
  color: #ffba4a;
  padding: 3px 5px;
  transform: scale(0.6, 0.6);
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 46px;
}

.price-txt::after {
  display: block;
  position: absolute;
  content: " ";
  left: -6px;
  z-index: 2;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 7px solid #3c2c1f;
}
.cFF6969 {
  color: #ff6969;
}
</style>