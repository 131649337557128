import MQTT from "@/common/mqtt";
import { setMessageUserNo } from "@/http.js"
import { Notification } from 'element-ui';
let merchantInfoVo = null;
export function getMerchantInfoVo() {
    if (merchantInfoVo) return merchantInfoVo;
    return merchantInfoVo = JSON.parse(localStorage.getItem("merchantInfoVo"))
}
export default function ({ messageFn, themeNo }) {
    let { userNo } = getMerchantInfoVo();
    let userId = `PC_${userNo}_${new Date().getTime()}`;
    let messageHold = function (data) {
        //console.log("message=>",data)
        if (data.messageUserNo === userId) return;
        Notification.info({
            title: '消息',
            message: data.topicMessage
        })
        messageFn(data);
    }
    let option = {
        userId,
        themeNo,
        messageFn: messageHold
    };
    setMessageUserNo(userId);
    // eslint-disable-next-line no-undef
    return MQTT(option)
}