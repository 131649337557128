<template>
  <div v-loading="loading">
    <el-form
      :inline="true"
      :model="formzinernil"
      class="demo-form-inline"
      size="mini"
      ref="formzinernil"
      :rules="rules"
    >
      <el-form-item label="充值金额:" prop="money">
        <span class="redfont">{{ruleForm.money}}元</span>
      </el-form-item>
      <div v-if="ruleForm.paymentType=='1'">
        <el-form-item label="现金金额:" prop="realPayAmt">
          <el-input v-model="formzinernil.realPayAmt" placeholder="请输入现金金额" @input="givechange"></el-input>
        </el-form-item>
        <el-form-item label="找零:" prop="changeAmount">
          <span
            class="redfont"
          >{{formzinernil.changeAmount?formzinernil.changeAmount:"0"}}元</span>
        </el-form-item>
      </div>
      <div v-if="ruleForm.paymentType=='3'">
        <el-form-item label="刷卡金额:" prop="realPayAmt">
          <el-input v-model="formzinernil.realPayAmt" placeholder="请输入刷卡金额"></el-input>
        </el-form-item>
      </div>
      <div v-if="ruleForm.paymentType=='4'">
        <el-form-item label="其他充值方式:" prop="otherPaymentRemark">
          <el-input v-model="formzinernil.otherPaymentRemark" maxlength="30" placeholder="请输入充值方式"></el-input>
        </el-form-item>
        <el-form-item label="已收金额:" prop="realPayAmt">
          <el-input v-model="formzinernil.realPayAmt" placeholder="请输入已收金额"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitedzin" size="mini">确认充值</el-button>
      <el-button @click="close" size="mini">取 消</el-button>
    </span>
  </div>
</template>

<script>
import number from "@/common/num.js";
export default {
  mounted() {},
  props: { ruleForm: Object },
  data() {
    var numzin = (rule, value, callback) => {
      let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0.01—999999.99之间"));
      }
      callback();
    };
    var numzint = (rule, value, callback) => {
      let rez = /^(?!0(\.0{0,2})?$)[0-9]{1,6}(\.[0-9]{1,2})?$/;
      if (!rez.test(value)) {
        return callback(new Error("支持0.01—999999.99之间"));
      }
      if (number(value) < number(this.ruleForm.money)) {
        return callback(new Error("现金金额不能小于充值金额"));
      }
      callback();
    };
    return {
      loading: false,
      formzinernil: {
        paymentType: "",
        realPayAmt: "",
        changeAmount: "",
        otherPaymentRemark: "",
      },
      rules: {
        realPayAmt: [
          { required: true, message: "必填", trigger: "blur" },
          { validator: numzint, trigger: "blur" },
        ],
        otherPaymentRemark: [
          { required: true, message: "必填", trigger: "blur" },
        ],
      },
      result: "",
      code: "",
      timer: null,
    };
  },
  computed: {},
  watch: {},
  methods: {
    givechange() {
      if( number(this.formzinernil.realPayAmt) >= number(this.ruleForm.money)){
        this.loading = true;
          this.$http
            .post("/cashier-api/api/order/cashChangeAmount", {
              shouldReceiveAmount: number(this.ruleForm.money),
              cashPaymentAmount: number(this.formzinernil.realPayAmt),
            })
            .then((res) => {
              this.loading = false;
              this.formzinernil.changeAmount= res.cashChangeAmount/100;
              this.ruleForm.money = res.shouldReceiveAmount/100;
            })
            .catch((error) => {
              this.loading = false;
            });
      }
          
    },
    // givechange() {
    //   this.$refs["formzinernil"].validate((valid) => {
    //     if (valid) {
    //     this.formzinernil.changeAmount=Number(this.formzinernil.realPayAmt)-Number(this.ruleForm.money)
    //     }
    //   });
    // },
    close() {
      this.$emit("close");
      this.formzinernil.name = "";
      this.$refs["formzinernil"].resetFields();
    },
    submitedzin() {
      this.$refs["formzinernil"].validate((valid) => {
        if (valid) {
          this.formzinernil.paymentType = this.ruleForm.paymentType;
          let data = { ...this.formzinernil };
          data.realPayAmt = number(data.realPayAmt);
          data.chargeAmt= number(this.ruleForm.money)
          this.loading = true;
          this.$http
            .post("/cashier-api/f/member/order/charge/pay", {
              ...data,
              ...this.ruleForm
            })
            .then((res) => {
              this.loading = false;
              this.$message({
                type: "success",
                offset: 160,
                message: "充值成功!",
              });
              let data = {
                ...res.data,
              };
              this.$emit("close");
              this.$emit("status", data);
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.redfont {
  color: #ff6969;
}
</style>